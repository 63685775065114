import React from 'react';
import { Stack, Typography } from '@mui/material';
import { IGetZone } from 'types/zone';

interface IProps {
  zone: IGetZone;
}

function NAIList({ zone }: IProps) {
  return (
    <Stack>
      {zone?.nais?.map((nai) => (
        <Stack key={nai.id} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{nai.name}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}

export default NAIList;
