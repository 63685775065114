import React from 'react';
import { Box, styled } from '@mui/material';

interface Props {
  backgroundColor: string;
  color?: string;
  children: React.ReactNode;
  width?: string;
  height?: string;
  padding?: string;
}

const StyledIcon = styled(Box)`
  width: 30px;
  height: 22px;
  position: relative;
  border-radius: 100px;

  svg {
    position: absolute;
    width: 18px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default function IconFrame({
  color = 'white',
  backgroundColor,
  children,
  width = '30px',
  height = '24px',
  padding = '2px 3px',
}: Props) {
  return (
    <StyledIcon
      sx={{
        color,
        backgroundColor,
        width,
        height,
        padding,
      }}
    >
      {children}
    </StyledIcon>
  );
}
