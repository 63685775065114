import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  MenuItem,
  Slide,
  styled,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { colors } from 'theme';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'features/login/authProvider';
import RoleSwitcher from './roleSwitcher';
import TopNavigation from './topNavigation';
import { NavigationItem } from './navigation';

export const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="left" ref={ref} {...props} />,
);

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: ${() => colors.grayscale.b3};
    align-self: flex-start;
    margin-top: 65px;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  .MuiDialogContentText-root {
    background-color: ${colors.grayscale.b1};
    background-image: none;
    box-shadow: 0 10px 20px RGBA(0, 0, 0, 0.25);
    border-radius: 16px;
    margin-top: 9px;
    padding: 5px;
  }
`;

interface IProps {
  navigationItems: NavigationItem[];
}

export default function MobileMenu({ navigationItems }: IProps) {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>{open ? <CloseIcon /> : <MenuIcon />}</IconButton>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="mobile-menu"
        hideBackdrop
        fullScreen
      >
        <StyledDialogContent>
          <DialogContentText onClick={() => setOpen(false)}>
            <Box ml={2}>
              <TopNavigation pathname={pathname} navigationItems={navigationItems} orientation="vertical" />
            </Box>
            <Box m={2}>
              <Divider />
            </Box>
            <Box ml={2} mr={2}>
              <RoleSwitcher />
            </Box>
            <Box m={2}>
              <Divider />
            </Box>
            <Box m={2}>
              <MenuItem onClick={logout} disableRipple>
                <Typography variant="body1" color="error">
                  Вийти
                </Typography>
              </MenuItem>
            </Box>
          </DialogContentText>
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
}
