import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DangerTile from './dangerTile';
import { colors } from '../../../theme';
import DangerSwitcher from './dangerSwitcher';

interface Props {
  danger: boolean;
  onChange: (_checked: boolean) => void;
}

function GbuThreat({ danger, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <DangerTile danger={danger}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={danger ? colors.grayscale.b1 : colors.grayscale.w0} variant="h2">
          {t('gbu_threat')}
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <DangerSwitcher checked={danger} onChange={() => onChange(!danger)} />
          <Box width="130px">
            <Typography color={danger ? colors.grayscale.b1 : colors.grayscale.w1} variant="h3">
              {danger ? t('there_is_a_threat') : t('no_threat')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </DangerTile>
  );
}

export default GbuThreat;
