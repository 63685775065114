import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Dialog, Hidden, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IMission, IMissionsFilterDto } from 'types/mission';
import { urls } from 'router';
import { colors } from 'theme';
import { ArchiveIcon } from 'assets/icons';
import PageHeader from 'components/pageHeader';

import { Transition } from 'components/header/mobileMenu';
import MissionDetailsSidebar from '../components/missionDetailsSidebar';
import MissionByStatus from '../components/missionByStatus';
import MissionFilters from '../components/missionFilters';
import MissionFilterChips from '../components/missionFilterChips';
import MissionFilterBadge from '../components/missionFilterBadge';

import { extendMissionsWithZones, getFilters } from './utils';
import { useZones, useCrews, useMissions } from '../services/hooks';

function Missions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const [filters, setFilters] = useState<IMissionsFilterDto>({ ...getFilters(), groupedBy: 'status' });

  const [missions, setMissions] = useState<IMission[]>([]);
  const [selectedMissionId, setSelectedMissionId] = useState<string | null>();
  const [isFiltersVisible, setFilterVisibility] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);

  const { data: zones = [] } = useZones();
  const { data: crews = [] } = useCrews();

  const {
    data: missionsData,
    isFetching: isMissionsFetching,
    refetch: refetchMissions,
  } = useMissions({ search, limit: 1000, filters, page: 1 });

  useEffect(() => {
    if (missionsData?.results && !isMissionsFetching) {
      setMissions(extendMissionsWithZones(missionsData.results, zones));
    }
  }, [missionsData, zones, isMissionsFetching]);

  const selectMissionAndScrollTop = (mission: IMission) => {
    if (selectedMissionId === mission.id) {
      setSelectedMissionId(null);
    } else {
      setSelectedMissionId(mission.id);
      const element = document.getElementById('sidebar-info');
      element?.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  const selectedMission = missions.find((m: IMission) => m.id === selectedMissionId);

  const updateFilter = (filter: IMissionsFilterDto) => {
    setFilters(filter);
    localStorage.setItem('filters', JSON.stringify(filter));
    refetchMissions();
  };

  useEffect(() => {
    delete filters.status;
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  return (
    <Stack sx={{ paddingTop: '12px' }}>
      <PageHeader
        title={t('missions')}
        onCreate={() => navigate(urls.missions.create, { state: { zones } })}
        createLabel={t('createMission')}
      />
      <Stack flexDirection="row" justifyContent="space-between" gap={2} mb={2} mt="11px">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Button
            sx={{ height: '36px', width: filterCount > 0 ? '113px' : '89px' }}
            onClick={() => setFilterVisibility(true)}
            variant="outlined"
            color="primary"
            size="small"
          >
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1"> {t('filters')} </Typography>
              {filterCount > 0 && <MissionFilterBadge filterCount={filterCount} />}
            </Stack>
          </Button>
          <MissionFilterChips
            filters={filters}
            deleteFilterChip={(f: IMissionsFilterDto) => updateFilter(f)}
            onSetFilterCount={(count) => setFilterCount(count)}
          />
        </Stack>
        <Button
          onClick={() => navigate(urls.missions.archive)}
          variant="text"
          sx={{
            color: '#AFAFAF',
            border: 'none',
            padding: '8px 12px',
            '&:hover': { backgroundColor: colors.brand.b3, color: colors.grayscale.w1 },
          }}
        >
          <Stack mr="8px">
            <ArchiveIcon width={20} height={20} />
          </Stack>
          {t('archived')}
        </Button>
      </Stack>
      <MissionFilters
        open={isFiltersVisible}
        onClose={() => setFilterVisibility(false)}
        filters={filters}
        setFilters={(changedFilters) => setFilters({ ...changedFilters, groupedBy: 'status' })}
        zones={zones}
        crews={crews}
        submitFilters={() => refetchMissions()}
      />

      <Stack flexDirection="row" mt="3px">
        <Stack flex={1}>
          <MissionByStatus
            missions={missions}
            selectedMission={selectedMission}
            selectMissionAndScrollTop={selectMissionAndScrollTop}
            isFetching={isMissionsFetching}
          />
        </Stack>
        {selectedMission && (
          <Stack>
            <Hidden mdDown>
              <MissionDetailsSidebar mission={selectedMission} setSelectedMission={setSelectedMissionId} />
            </Hidden>
            <Hidden mdUp>
              <Dialog
                open={!!selectedMission}
                TransitionComponent={Transition}
                fullScreen
                sx={{
                  marginTop: '30px',
                  '& .MuiDialog-paper': {
                    borderRadius: '20px',
                  },
                }}
              >
                <MissionDetailsSidebar mission={selectedMission} setSelectedMission={setSelectedMissionId} />
              </Dialog>
            </Hidden>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default Missions;
