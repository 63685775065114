import React from 'react';
import * as _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Stack,
  styled,
  Typography,
  Divider,
} from '@mui/material';

import { KeyboardArrowUp } from '@mui/icons-material';
import { IMission } from 'types/mission';
import { getDayDate } from 'services/dayjs';
import { colors } from 'theme';
import MissionCard from './missionCard';
import StatusLabel from '../../../components/statusLabelDropdown';

interface Props {
  name: string;
  expanded: boolean;
  missions?: IMission[];
  selectMission: (_mission: IMission) => void;
  selectedMissionId?: string;
  isFetching?: boolean;
}

const KHAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'customPadding',
})<{ customPadding: string }>`
  .MuiAccordionSummary-root {
    padding: 0;
    margin: 0;
    height: 56px;
    &.Mui-expanded {
      min-height: 56px;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 ${({ customPadding }) => customPadding} 0 0;
  }
  .MuiCardContent-root {
    box-sizing: border-box;
    padding: 12px;
    &:last-child {
      padding: 12px;
    }
  }
`;

function MissionGroup({
  missions = [],
  name,
  expanded,
  selectMission,
  selectedMissionId = '',
  isFetching = false,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(expanded);

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  function filterByDay(missionsList: IMission[] | undefined) {
    return _.groupBy(missionsList, (m) => getDayDate(m.due_date));
  }

  const missionsByDate = filterByDay(missions);

  if (isFetching) return <Skeleton height={50} />;

  return (
    <Stack>
      {name !== 'finished' && (
        <Divider
          sx={{
            marginRight: selectedMissionId ? '18px' : '0',
          }}
        />
      )}
      <KHAccordion
        expanded={isExpanded}
        onChange={(e, exp) => setIsExpanded(exp)}
        customPadding={selectedMissionId ? '18px' : '0'}
      >
        {name !== 'finished' ? (
          <AccordionSummary>
            <Box display="flex" justifyContent="center" alignItems="center">
              <KeyboardArrowUp
                style={{
                  color: colors.grayscale.g1,
                  transform: !isExpanded ? 'rotate(180deg)' : '',
                  transition: '300ms ease-out',
                }}
              />
              <Stack sx={{ ml: 1 }}>
                <StatusLabel currentStatus={name} type="mission" size="sm" />{' '}
              </Stack>
              <Typography variant="body1" color={colors.grayscale.g2} pl="10px" pt="2px">
                {missions?.length || '0'}
              </Typography>
            </Box>
          </AccordionSummary>
        ) : (
          <Stack mt={2} />
        )}
        <AccordionDetails>
          <Stack>
            {Object.keys(missionsByDate).map((date: string) => (
              <div key={date}>
                <Typography variant="body2" mb="8px">
                  {date}
                </Typography>
                {missionsByDate[date]?.map((mission) => (
                  <MissionCard
                    mission={mission}
                    key={mission.id}
                    selectMission={selectMission}
                    selectedMissionId={selectedMissionId}
                  />
                ))}
              </div>
            ))}
          </Stack>
        </AccordionDetails>
      </KHAccordion>
    </Stack>
  );
}

export default MissionGroup;
