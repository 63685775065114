import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function RSZV({ width = 23, height = 23 }: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      width={width}
      height={height}
      baseProfile="tiny"
      viewBox="26 26 148 164"
    >
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#ffffff"
        fill="none"
      />
      <g transform="translate(0,172)" strokeWidth="4" stroke="black" fill="none">
        <path
          d="M 53,1 l 94,0"
          strokeWidth="44"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="none"
          fill="none"
        />
        <circle
          cx="58"
          cy="8"
          r="8"
          strokeWidth="44"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="none"
          fill="none"
        />
        <circle
          cx="142"
          cy="8"
          r="8"
          strokeWidth="44"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="none"
          fill="none"
        />
        <circle
          cx="100"
          cy="8"
          r="8"
          strokeWidth="44"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="none"
          fill="none"
        />
      </g>
      <path
        d="M100,28 L172,100 100,172 28,100 100,28 Z Z"
        strokeWidth="44"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
        fill="none"
      />
      <path
        d="M100,28 L172,100 100,172 28,100 100,28 Z"
        strokeWidth="4"
        stroke="black"
        fill="rgb(255,128,128)"
        fillOpacity="1"
      />
      <path
        d="m 115,90 0,40 m -30,-40 0,40 m 0,-55 15,-15 15,15 m -15,-5 0,70 M 85,85 100,70 115,85"
        strokeWidth="4"
        stroke="black"
        fill="none"
      />
      <g transform="translate(0,172)" strokeWidth="4" stroke="black" fill="none">
        <path d="M 53,1 l 94,0" />
        <circle cx="58" cy="8" r="8" />
        <circle cx="142" cy="8" r="8" />
        <circle cx="100" cy="8" r="8" />
      </g>
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="4"
        stroke="none"
        fill="#000000"
      />
    </svg>
  );
}

export default RSZV;
