import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { IGetRelatedMissions, IRelatedMissions } from 'types/zone';
import { getDefaultDate } from 'services/dayjs';
import GroupBy from 'components/groupBy';
import RelatedMissionTable from './relatedMissionTable';
import statusMissins from '../services/constants';

interface IReletedMissionProps {
  missions: IGetRelatedMissions[] | undefined;
  isLoading: boolean;
}

function RelatedMissions({ missions, isLoading }: IReletedMissionProps) {
  const groupParam = Object.keys(statusMissins)[0];
  const [groupedBy, setGroupedBy] = useState(groupParam);
  const [relatedMissions, setRelatedMissions] = useState<IRelatedMissions[]>([]);

  const filterMissionsByStatus = (missionList: IGetRelatedMissions[], status: string) =>
    missionList.filter((mission: any) => mission.status === status);

  useEffect(() => {
    setRelatedMissions(() => {
      if (missions?.length) {
        let filteredMissions = missions;

        if (groupedBy && statusMissins[groupedBy] !== 'all') {
          filteredMissions = filterMissionsByStatus(missions, statusMissins[groupedBy]);
        }

        return (filteredMissions ?? []).map((mission: IGetRelatedMissions) => ({
          id: mission.id,
          name: mission.name,
          crew: mission.crew?.name ?? 'Unknown crew',
          uav: mission.crew?.uav ?? 'Unknown uav',
          crewStatus: mission.crew?.status,
          lastUpdate: getDefaultDate(mission.updated_at),
          status: mission.status as 'planned' | 'in_progress' | 'completed' | 'not_completed' | 'suspended',
        }));
      }
      return [];
    });
  }, [missions, groupedBy]);

  return (
    <Stack sx={{ marginTop: '10px' }}>
      <Stack sx={{ marginTop: '6px' }}>
        <GroupBy
          items={Object.keys(statusMissins)}
          selected={groupedBy}
          onChange={(group: string) => setGroupedBy(group)}
          label="status"
        />
      </Stack>
      <Stack sx={{ marginTop: '16px' }}>
        <RelatedMissionTable rows={relatedMissions} isLoading={isLoading} />
      </Stack>
    </Stack>
  );
}

export default RelatedMissions;
