import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import api, { apiURLs } from 'services/api';
import { IGetZoneListResponse } from 'types/zone';
import { BaseFilters } from 'services/model';

export const useZones = ({ filters }: { filters: BaseFilters }) => {
  const { data, isFetching, refetch } = useQuery<IGetZoneListResponse, Error>({
    queryKey: ['zone-list', filters],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.zones.listExtended(filters));
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch zones');
        throw err;
      }
    },
  });

  return { data, isFetching, refetch };
};

export default useZones;
