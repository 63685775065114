import React, { useEffect } from 'react';
import { Stack, Chip, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme';

import MissionTypeIcon from 'components/missionTypeIcon';

const ChipStyled = styled(Chip)`
  background-color: ${colors.brand.b3_2};
  color: ${colors.grayscale.w0};
  border-radius: 24px;
  height: 36px;
  width: auto;
  font-size: 14px;
  padding: 8px 12px;
  & span {
    padding: 0;
    margin: 0 8px;
  }
  & .MuiChip-deleteIcon {
    margin: 0;
  }
`;

const ChipCountStyled = styled(Chip)`
  background-color: ${colors.brand.b3_2};
  color: ${colors.grayscale.w0};
  border-radius: 24px;
  height: 36px;
  width: 60px;
`;

interface MissionFilterChipsProps {
  filters: any;
  deleteFilterChip: (_updatedFilters: any) => void;
  onSetFilterCount: (_count: number) => void;
}

function MissionFilterChips({ filters, deleteFilterChip, onSetFilterCount }: MissionFilterChipsProps) {
  const { t } = useTranslation();

  const { type, status, zones, crews } = filters;

  const allFilters = [
    ...(type ? [{ label: type, type: 'type' }] : []),
    ...(status ? [{ label: status, type: 'status' }] : []),
    ...(zones ? zones.map((zone: any) => ({ label: zone.name, type: 'zone', data: zone })) : []),
    ...(crews ? crews.map((crew: any) => ({ label: crew.name, type: 'crew', data: crew })) : []),
  ];

  useEffect(() => {
    onSetFilterCount(allFilters.length as number);
  });

  const visibleFilters = allFilters.slice(0, 3);
  const hiddenCount = allFilters.length - visibleFilters.length;

  const removeFilter = (filter: any) => {
    const updatedFilters = { ...filters };
    switch (filter.type) {
      case 'type':
        delete updatedFilters.type;
        break;
      case 'status':
        delete updatedFilters.status;
        break;
      case 'zone':
        updatedFilters.zones = updatedFilters.zones.filter((z: any) => z.id !== filter.data.id);
        break;
      case 'crew':
        updatedFilters.crews = updatedFilters.crews.filter((c: any) => c.id !== filter.data.id);
        break;
      default:
        break;
    }
    deleteFilterChip(updatedFilters);
  };

  return (
    <Stack direction="row" spacing={1}>
      {visibleFilters.map((filter) => (
        <ChipStyled
          key={filter.label}
          label={filter.type === 'type' || filter.type === 'status' ? t(filter.label) : filter.label}
          deleteIcon={<CloseIcon sx={{ width: '20px', height: '20px', margin: '0px' }} />}
          onDelete={() => removeFilter(filter)}
          icon={
            filter.type === 'type' ? (
              <MissionTypeIcon
                height="20px"
                width="20px"
                padding="0px"
                backgroundDisabled
                type={`${filter.label}Active`}
              />
            ) : undefined
          }
        />
      ))}
      {hiddenCount > 0 && <ChipCountStyled label={`+${hiddenCount}`} />}
    </Stack>
  );
}

export default MissionFilterChips;
