import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import FakeModal from 'components/fakeModal';
import { urls } from 'router';
import { ICreateZoneDto, IZone, ZoneFormModes } from 'types/zone';
import defaultZone from 'defaults/zone';
import { getValidationErrors } from 'services/helpers';
import ZoneForm from '../components/zoneForm';

import { useSubmitZone } from '../services/hooks';

interface IProps {
  currentZone?: IZone | ICreateZoneDto;
}

const schema = yup
  .object({
    name: yup.string().required('name_required').min(2, 'name_too_short'),
  })
  .required();

function CreateZone({ currentZone = defaultZone }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [zone, setZone] = React.useState<ICreateZoneDto | IZone | undefined>(defaultZone);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => setZone(currentZone), [currentZone]);

  const submitZone = useSubmitZone(() => navigate(urls.zones.landing));

  function validateAndSubmit() {
    schema
      .validate(zone, { abortEarly: false })
      .then(() => {
        setErrors({});
        if (zone) {
          submitZone.mutate(zone as ICreateZoneDto);
        }
      })
      .catch((err: ValidationError) => {
        setErrors(getValidationErrors(err));
      });
  }

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('create_zone')}
        cancel={() => navigate(urls.zones.landing)}
        onSubmit={() => validateAndSubmit()}
      >
        {/* @ts-ignore */}
        <ZoneForm zone={zone} setZone={setZone} errors={errors} mode={ZoneFormModes.CREATE} />
      </FakeModal>
    </Container>
  );
}

export default CreateZone;
