import React from 'react';
import { Stack } from '@mui/material';
import { IMission } from 'types/mission';
import UavTypeIconWithText from '../../../components/uavTypeIconWithText';

interface IProps {
  mission: IMission;
}

function MissionCardZone({ mission }: IProps) {
  return (
    <Stack flexDirection="row" gap="4px">
      {mission.zones?.map((zone) => {
        const nais = mission.nais
          ?.filter((nai) => nai.zoneId === zone?.id)
          .map((nai) => nai.name)
          .join(', ');
        const name = `${zone?.name} (${nais})`;
        return (
          <span title={zone?.description} key={zone?.id}>
            <UavTypeIconWithText name={name} type="zone" />
          </span>
        );
      })}
    </Stack>
  );
}

export default MissionCardZone;
