import React from 'react';
import { SparkIcon, BinocularIcon, BombIcon, MineIcon } from 'assets/icons';
import { colors } from 'theme';
import IconFrame from '../iconFrame';

interface Props {
  type: string;
  backgroundDisabled?: boolean;
  height?: string;
  width?: string;
  padding?: string;
}

const iconMap = {
  recon: {
    icon: <BinocularIcon fill={colors.mission.recon['500']} />,
    backgroundColor: colors.mission.recon['900'],
    color: '#FE8981',
  },
  reconActive: {
    icon: <BinocularIcon fill={colors.grayscale.w1} />,
    backgroundColor: 'RGBA(255, 255, 255, .2)',
    color: '#FE8981',
  },
  strike: {
    icon: <SparkIcon fill={colors.mission.strike['500']} />,
    backgroundColor: colors.mission.strike['900'],
    color: '#FE8981',
  },
  strikeActive: {
    icon: <SparkIcon fill={colors.grayscale.w1} />,
    backgroundColor: 'RGBA(255, 255, 255, .2)',
    color: '#FE8981',
  },
  bomb: {
    icon: <BombIcon />,
    backgroundColor: '#5A3131',
    color: '#FE8981',
  },
  bombActive: {
    icon: <BombIcon />,
    backgroundColor: 'RGBA(255, 255, 255, .2)',
    color: '#FFFFFF',
  },
  mine: {
    icon: <MineIcon fill={colors.mission.mine['500']} />,
    backgroundColor: colors.mission.mine['900'],
    color: '#FE8981',
  },
  mineActive: {
    icon: <MineIcon fill={colors.grayscale.w1} />,
    backgroundColor: 'RGBA(255, 255, 255, .2)',
    color: '#FE8981',
  },
};

function MissionTypeIcon({ type, backgroundDisabled = false, height = '18px', width = '24px', padding }: Props) {
  const item = iconMap[type as keyof typeof iconMap];
  if (!item) {
    return null;
  }
  return (
    <IconFrame
      width={width}
      height={height}
      padding={padding}
      backgroundColor={!backgroundDisabled ? item.backgroundColor : ''}
      color={item?.color}
    >
      {item.icon}
    </IconFrame>
  );
}

export default MissionTypeIcon;
