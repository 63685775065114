import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api, { apiURLs } from '../../../services/api';
import { ICrew, IGetCrewListResponse } from '../../../types/crew';
import { IGetMissionListResponse } from '../../../types/mission';

// eslint-disable-next-line import/prefer-default-export
export const useUpdateCrewStatus = (crew: ICrew) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['crew-status'],
    mutationFn: ({ id, status, report }: any) =>
      api
        .patch(apiURLs.crews.status(id), { status, report: report || '' })
        .then(({ data }) => {
          toast.success(t('crew_status_updated'));
          return data;
        })
        .catch((err) => toast.error(err.response.data?.error)),
    onSuccess: (u: ICrew) => {
      queryClient.setQueryData(['my-crew'], (c: ICrew) => ({ ...c, ...u }));
      queryClient.setQueryData(['crew-details', crew.id], (c: ICrew) => ({ ...c, ...u }));
      queryClient
        .getQueryCache()
        .findAll({ queryKey: ['mission-list'] })
        .forEach(({ queryKey }) => {
          queryClient.setQueryData(
            queryKey,
            (missionsResp: IGetMissionListResponse) =>
              missionsResp && {
                ...missionsResp,
                results: missionsResp.results.map((m) => {
                  if (m.crew.id === crew.id) {
                    return { ...m, crew: u };
                  }
                  return m;
                }),
              },
          );
        });
      queryClient
        .getQueryCache()
        .findAll({ queryKey: ['crew-list'] })
        .forEach(({ queryKey }) => {
          queryClient.setQueryData(
            queryKey,
            (crewsResp: IGetCrewListResponse) =>
              crewsResp && {
                ...crewsResp,
                results: crewsResp.results.map((c: ICrew) => {
                  if (c.id === u.id) {
                    return u;
                  }
                  return c;
                }),
              },
          );
        });
    },
  });
};
