import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Stack, styled, Typography } from '@mui/material';

import { colors } from 'theme';

import { IMission } from 'types/mission';
import { urls } from 'router';

import StatusLabel from '../../../components/statusLabelDropdown';
import TargetSign from '../../targets/components/targetSign';
import TargetCounts from '../../targets/components/targetCounts';

interface IProps {
  activeMissions?: IMission[];
}

const MissionsCard = styled(Card)(() => ({
  marginBottom: '8px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
}));

function CrewMissions({ activeMissions = [] }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Stack flexDirection="row" gap={1} mb={2}>
        <Typography variant="h2" color={colors.grayscale.g2}>
          {t('missions')}
        </Typography>
        <Typography variant="h2" color={colors.grayscale.g1}>
          {activeMissions?.length || 0}
        </Typography>
      </Stack>
      {activeMissions?.map((mission) => (
        <MissionsCard key={mission.id}>
          <Stack p={2} pb={1} flexDirection="column">
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb="6px">
              <Typography variant="body1" mb={1}>
                {mission.name}
              </Typography>
              <StatusLabel type="mission" currentStatus={mission.status} id={mission.id} />
            </Stack>
            {mission.target.map((target) => (
              <Stack flexDirection="row" justifyContent="space-between" key={target.id}>
                <Stack flexDirection="row" alignItems="center" mb={1} gap="5px">
                  <Typography variant="body2">{target.name}</Typography>
                  <TargetSign sidc={target.app6d_sidc} />
                </Stack>
                <Stack>
                  <TargetCounts photos={target.images.length} missions={+1} comments={+1} />
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Stack flexDirection="row" p={2} pt={0}>
            <Button onClick={() => navigate(urls.missions.details(mission.id))} variant="outlined" color="primary">
              {t('open_mission')}
            </Button>
          </Stack>
        </MissionsCard>
      ))}
    </>
  );
}

export default CrewMissions;
