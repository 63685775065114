import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { colors } from 'theme';
import { useTranslation } from 'react-i18next';
import { dayjs } from '../../services/dayjs';
import { IReport } from '../../types/mission';

const Wrapper = styled(Box)<{ color?: string }>`
  border: 2px solid ${(props) => props.color};
  border-radius: 10px;
  padding: 10px;
`;

interface IProps {
  color?: 'yellow' | 'red';
  title: string;
  reason: IReport | undefined;
}

function ReasonAlert({ title, reason, color = 'yellow' }: IProps) {
  const { t } = useTranslation();
  const textColor = color === 'red' ? colors.mission.strike['500'] : colors.system.yellow1;
  const borderColor = color === 'red' ? colors.mission.strike['900'] : colors.system.yellow2;
  const text = reason ? reason.text : t('no_reason_entered');

  return (
    <Wrapper color={borderColor}>
      <Typography color={textColor} variant="body2">
        {title}
      </Typography>
      <Typography sx={{ pt: 1 }} variant="body1">
        {text}
      </Typography>
      {reason?.created_at && (
        <Typography sx={{ pt: 1 }} color={colors.grayscale.g1} variant="body2">
          {dayjs(reason?.created_at).fromNow()}
        </Typography>
      )}
    </Wrapper>
  );
}

export default ReasonAlert;
