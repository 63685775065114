import React from 'react';

export default function SuccessTickIcon() {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="17.5" r="17.5" fill="#7BB350" />
      <g>
        <path
          d="M14.5002 21.6698L10.3302 17.4998L8.91016 18.9098L14.5002 24.4998L26.5002 12.4998L25.0902 11.0898L14.5002 21.6698Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_765_23724">
          <rect width="24" height="24" fill="white" transform="translate(5.5 5.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
