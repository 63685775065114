import React from 'react';
import { Box } from '@mui/material';
import { colors } from 'theme';

interface MissionFilterBadgeProps {
  filterCount: number;
}

function MissionFilterBadge({ filterCount }: MissionFilterBadgeProps) {
  return (
    <Box
      sx={{
        fontSize: '14px',
        backgroundColor: colors.system.notification,
        color: '#171717',
        borderRadius: '50%',
        marginLeft: '4px',
        width: '20px',
        height: '20px',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {filterCount}
    </Box>
  );
}

export default MissionFilterBadge;
