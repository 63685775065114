import React, { useState } from 'react';
import PageHeader from 'components/pageHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Hidden, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import api, { apiURLs } from 'services/api';
import { urls } from 'router';
import { IGetTargetListResponse, ITarget } from 'types/target';
import { BaseFilters } from 'services/model';
import { TARGET_STATUSES } from 'constants/target';
import { Transition } from 'components/header/mobileMenu';
import TargetGroup from '../components/targetGroup';
import TargetDetails from '../components/targetDetails';

function Targets() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const filters = { page: 1, limit: 100, search } as BaseFilters;
  const [selectedTargetId, setSelectedTargetId] = useState<string | null>();
  const [targets, setTargets] = useState<ITarget[]>([]);

  const { data, isFetching } = useQuery({
    queryKey: ['target-list', filters],
    queryFn: (): Promise<IGetTargetListResponse> =>
      api
        .get(apiURLs.targets.list(filters))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  const archiveTarget = useMutation({
    mutationFn: (params: { id: string; isArchiving: boolean }) => api.patch(apiURLs.targets.archive(params.id)),
    onSuccess: (d, variables) => {
      const message = variables.isArchiving ? t('target_archived_successfully') : t('target_unarchived_successfully');
      toast.success(message);
      queryClient.invalidateQueries({ queryKey: ['target-list', filters] });
    },
    onError: () => toast.error('target_archivation_error'),
  });
  React.useEffect(() => {
    if (data?.results && !isFetching) {
      setTargets(data.results);
    }
  }, [data, isFetching]);

  const filterTargetStatus = (status: string, targetList: ITarget[] | undefined) =>
    targetList?.filter((target) => target.status === status);

  const selectTargetAndScrollTop = (target: ITarget) => {
    if (selectedTargetId === target.id) {
      setSelectedTargetId(null);
    } else {
      setSelectedTargetId(target.id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const selectedTarget = targets.find((tar: ITarget) => tar.id === selectedTargetId);

  return (
    <>
      <PageHeader
        title={t('targets')}
        onCreate={() => navigate(urls.targets.import)}
        createLabel={t('importTargetsFromDelta')}
      />
      <Stack flexDirection="row" gap={2}>
        <Stack gap={1} flex={1}>
          {TARGET_STATUSES.map((status: string) => {
            const filteredTargets = filterTargetStatus(status, targets);
            return (
              <TargetGroup
                name={status}
                targets={filteredTargets}
                selectTarget={selectTargetAndScrollTop}
                selectedTargetId={selectedTarget?.id}
                isFetching={isFetching}
                key={status}
                expanded={!!filteredTargets?.length}
              />
            );
          })}
        </Stack>
        <Hidden mdDown>
          {selectedTarget && (
            <TargetDetails
              target={selectedTarget}
              setSelectedTarget={setSelectedTargetId}
              onArchive={(id, isArchiving) =>
                archiveTarget.mutate({
                  id,
                  isArchiving,
                })
              }
            />
          )}
        </Hidden>
        <Hidden mdUp>
          <Dialog
            open={!!selectedTarget}
            TransitionComponent={Transition}
            fullScreen
            sx={{
              marginTop: '30px',
              '& .MuiDialog-paper': {
                borderRadius: '20px',
              },
            }}
          >
            {selectedTarget && (
              <TargetDetails
                target={selectedTarget}
                setSelectedTarget={setSelectedTargetId}
                onArchive={(id, isArchiving) =>
                  archiveTarget.mutate({
                    id,
                    isArchiving,
                  })
                }
              />
            )}
          </Dialog>
        </Hidden>
      </Stack>
    </>
  );
}

export default Targets;
