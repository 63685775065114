import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';

import MissionTypeIcon from 'components/missionTypeIcon';
import { ICrew } from 'types/crew';
import UavTypeIcon from '../../../components/uavTypeIcon';
import StatusLabel from '../../../components/statusLabelDropdown';
import { crewStatuses } from '../services/constants';

interface IProps {
  crew: ICrew;
  onStatusChange: (_status: string) => void;
}

function CrewDetailsInfo({ crew, onStatusChange }: IProps) {
  const { t } = useTranslation();
  const type = crew.uav[0]?.type;

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={12} mb={2}>
        <Typography variant="body2" mb={1}>
          {t('status')}
        </Typography>
        <StatusLabel
          type="crew"
          currentStatus={crew.status}
          options={crewStatuses}
          id={crew.id}
          onClick={(status: string) => onStatusChange(status)}
          selectable
        />
      </Grid>
      <Grid item xs={6} mb={2}>
        <Typography variant="body2" mb={1}>
          {t('type')}
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap="6px">
          <Typography variant="body1">{t(type)}</Typography>
          <MissionTypeIcon type={type} />
        </Stack>
      </Grid>
      <Grid item xs={6} mb={2}>
        <Typography variant="body2" mb={1}>
          {t('location')}
        </Typography>
        <Typography variant="body1">{crew.location?.name || '-'}</Typography>
      </Grid>
      <Grid item xs={6} mb={2}>
        <Typography variant="body2" mb={1}>
          {t('uav')}
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {crew.uav?.map((uav) => (
            <Typography variant="body1" key={uav.id}>
              {uav.name} • {3}/{5} од
            </Typography>
          ))}
          <UavTypeIcon type={type} />
        </Stack>
      </Grid>
      {crew.ammo?.length > 0 ? (
        <Grid item xs={6} mb={2}>
          <Typography variant="body2" mb={1}>
            {t('ammo')}
          </Typography>
          {crew.ammo?.map((ammo) => (
            <Typography variant="body1" key={ammo.id}>
              {ammo.name}
            </Typography>
          ))}
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}
      <Grid item xs={12} mb={2}>
        <Typography variant="body2" mb={1}>
          {t('crewMembers')}
        </Typography>
        <div className="crew-list">
          {crew.members?.map((member) => (
            <Typography variant="body1" className="crew-member" key={member.id}>
              {member.username} ({t(member.role)})
            </Typography>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default CrewDetailsInfo;
