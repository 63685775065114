import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton, Stack, styled, Typography } from '@mui/material';
import { uavTypes } from 'constants/mission';
import { colors } from 'theme';

import MissionTypeIcon from 'components/missionTypeIcon';

const ToggleButtonStyled = styled(ToggleButton)`
  background-color: transparent;
  color: ${colors.grayscale.w0};
  height: 36px;
  width: 130px;
  :hover {
    background-color: #354b2b;
    border: transparent;
  }
  &.Mui-selected {
    border-radius: 24px;
    background-color: #1a322c;
    border: transparent;
    &:hover {
      background-color: #354b2b;
    }
  }
  text-transform: none;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButtonGroup-firstButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
  .MuiToggleButtonGroup-middleButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
  .MuiToggleButtonGroup-lastButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
`;

interface IProps {
  selectedType: string | undefined;
  setSelectedType: (_type: string) => void;
}

function MissionTypeToggleButton({ selectedType, setSelectedType }: IProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" sx={{ padding: '6px 0 0 0' }}>
      <StyledToggleButtonGroup value={selectedType} onChange={(_, value) => setSelectedType(value)} exclusive>
        {uavTypes.map((type) => (
          <ToggleButtonStyled sx={{ margin: '0 7px 0 0px' }} key={type} value={type}>
            <Stack sx={{ paddingRight: '10px' }} flexDirection="row" alignItems="center" gap="1px">
              <MissionTypeIcon backgroundDisabled type={`${type}Active`} />
              <Typography> {t(type)} </Typography>
            </Stack>
          </ToggleButtonStyled>
        ))}
      </StyledToggleButtonGroup>
    </Stack>
  );
}

export default MissionTypeToggleButton;
