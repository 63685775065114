import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Stack } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Logo from '../../login/pages/Logo';

function FeedbackForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedbackReason, setFeedbackReason] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const handleFeedbackReasonChange = (event: SelectChangeEvent) => {
    setFeedbackReason(event.target.value as string);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#202020',
        color: '#ffffff',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
      }}
    >
      <Stack p={4} alignItems="center">
        <Stack mb="48px">
          <Logo />
        </Stack>
        <Box
          sx={{
            maxWidth: '500px',
            width: '100%',
            backgroundColor: '#171717',
            borderRadius: '24px',
            padding: '24px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          {submitted ? (
            <Typography variant="h6" align="center">
              Thank you for your feedback!
            </Typography>
          ) : (
            <form onSubmit={handleSubmit}>
              <Typography variant="h5" align="center" sx={{ marginBottom: '16px' }}>
                Feedback Form
              </Typography>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ marginBottom: '16px', backgroundColor: '#171717' }}
              />
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: '16px', backgroundColor: '#171717' }}
              />
              <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                <InputLabel>Reason for Feedback</InputLabel>
                <Select
                  value={feedbackReason}
                  onChange={handleFeedbackReasonChange}
                  label="Reason for Feedback"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '24px',
                      borderWidth: '2px',
                    },
                  }}
                >
                  <MenuItem value="positive">Positive Feedback</MenuItem>
                  <MenuItem value="bug">Bug Report</MenuItem>
                  <MenuItem value="suggestion">Feature Suggestion</MenuItem>
                  <MenuItem value="question">Question</MenuItem>
                  <MenuItem value="complaint">Complaint</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ marginBottom: '16px', backgroundColor: '#171717' }}
              />
              <Button
                disabled={!name || !email || !message || !feedbackReason}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ backgroundColor: 'primary', color: '#ffffff' }}
              >
                Submit
              </Button>
            </form>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default FeedbackForm;
