import React from 'react';
import { MapContainer, TileLayer, Polygon, CircleMarker, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { colors } from 'theme';
import { Stack } from '@mui/material';
import { toPoint } from 'mgrs';
import { INAI } from 'types/zone';

interface IProps {
  nais: INAI[];
  mapWidth?: string;
}

interface LatLngPoint {
  lat: number;
  lng: number;
}

const mapContainer = (mapWidth: string = '773px') => {
  return {
    width: mapWidth,
    height: '441px',
    border: `1px solid ${colors.grayscale.b5}`,
    overflow: 'hidden',
    borderRadius: '24px',
    marginTop: '10px',
    zIndex: 0,
  };
};

function NAIMap({ nais, mapWidth }: IProps) {
  const [position, setPosition] = React.useState<[number, number]>([49.9921745, 36.2306956]);

  const convertToLatLng = (mgrsPoints: string[]): LatLngPoint[] =>
    mgrsPoints.map((point) => {
      const [lng, lat] = toPoint(point);
      return { lat, lng };
    });

  const allPoints = React.useMemo(() => {
    return nais?.map((polygon) => (polygon.coordinates ? convertToLatLng(polygon.coordinates) : [])).flat();
  }, [nais]);

  React.useEffect(() => {
    if (allPoints?.length) {
      const globalCenter: [number, number] = [
        allPoints.reduce((sum, point) => sum + point.lat, 0) / allPoints.length,
        allPoints.reduce((sum, point) => sum + point.lng, 0) / allPoints.length,
      ];
      setPosition(globalCenter);
    }
  }, [allPoints]);

  function CenterMap() {
    const map = useMap();
    React.useEffect(() => {
      map.setView(position, map.getZoom());
    }, [map]);
    return null;
  }
  const tooltipStyle = `
    .custom-tooltip {
      background-color: transparent;
      font-family: 'e-Ukraine';
      color: white;
      font-size: 10px;
      font-weight: 500;
      border: none;
      box-shadow: none;
    }`;

  return (
    <Stack style={mapContainer(mapWidth)}>
      <style>{tooltipStyle}</style>

      <MapContainer
        style={{
          height: '100%',
          width: '100%',
        }}
        center={position}
        zoom={13}
        scrollWheelZoom={false}
      >
        <CenterMap />

        <TileLayer url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />

        {nais?.map((polygon) => {
          const latLngPoints = polygon.coordinates ? convertToLatLng(polygon.coordinates) : [];

          return (
            <Stack key={polygon.id}>
              {latLngPoints.length > 0 && (
                <Polygon positions={latLngPoints} pathOptions={{ color: colors.grayscale.w0, weight: 1 }}>
                  <Tooltip permanent direction="center" className="custom-tooltip">
                    {polygon.name}
                  </Tooltip>
                </Polygon>
              )}

              {latLngPoints.map((p, pointIndex) => (
                <CircleMarker
                  // eslint-disable-next-line react/no-array-index-key
                  key={pointIndex}
                  center={p}
                  pathOptions={{
                    color: colors.grayscale.w0,
                    fillColor: colors.grayscale.w0,
                    fillOpacity: 1,
                  }}
                  radius={3}
                />
              ))}
            </Stack>
          );
        })}
      </MapContainer>
    </Stack>
  );
}

export default NAIMap;
