import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { colors } from 'theme';
import { getTextFieldErrorProps, IError } from 'services/helpers';
import { Ammunition } from '../services/model';

const FormCard = styled(Card)(() => ({
  border: 'none',
}));

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
}));

interface Props {
  ammunition: Ammunition;
  setAmmunition: (_ammunition: Ammunition) => void;
  errors: IError;
}

function AmmunitionForm({ ammunition, setAmmunition, errors }: Props) {
  const { t } = useTranslation();

  return (
    <Stack>
      <FormCard>
        <FormCardContent>
          <Grid container p={4}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('name')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder={t('enter_name')}
                    value={ammunition?.name}
                    onChange={(e) => setAmmunition({ ...ammunition, name: e.target.value })}
                    size="small"
                    error={!!errors?.name}
                    helperText={t(errors?.name)}
                    sx={{ marginTop: errors?.name ? '20px' : '0' }}
                  />
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('warehouse_quantity')}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    placeholder="0"
                    type="number"
                    value={ammunition?.quantity}
                    onChange={(e) =>
                      setAmmunition({
                        ...ammunition,
                        quantity: parseInt(e.target.value, 10),
                      })
                    }
                    InputProps={{
                      endAdornment: <span className="input-end">од</span>,
                      inputProps: { min: 0 },
                    }}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'quantity', t)}
                  />
                </Grid>
                <Grid item xs={12} md={7} />
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('details')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder={t('add_details')}
                    value={ammunition?.description}
                    onChange={(e) => setAmmunition({ ...ammunition, description: e.target.value })}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={3} />
              </Grid>
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
    </Stack>
  );
}

export default AmmunitionForm;
