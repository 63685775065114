import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Divider, Stack, styled, Typography, Dialog, Hidden } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IGetZone } from 'types/zone';

import { colors } from 'theme';
import { urls } from 'router';
import PageHeader from 'components/pageHeader';
import UavTypeIconWithText from 'components/uavTypeIconWithText';
import { BaseFilters } from 'services/model';

import ZoneDetailsSidebar from '../components/zoneDetailsSidebar';
import MissionTypeIcon from '../../../components/missionTypeIcon';
import StatusLabel from '../../../components/statusLabelDropdown';
import UavTypeIcon from '../../../components/uavTypeIcon';
import { Transition } from '../../../components/header/mobileMenu';

import { useZones } from '../services/hooks';

const StyledStack = styled(Stack)(() => ({
  padding: '18px',
  backgroundColor: `${colors.grayscale.b2}`,
  borderRadius: '24px',
  border: 'none',
}));

function Zones() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const filters = { page: 1, limit: 10, search } as BaseFilters;

  const [zones, setZones] = useState<IGetZone[]>([]);
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: zoneList, isFetching: isFetchingZoneList, refetch } = useZones({ filters });

  useEffect(() => {
    if (zoneList?.results && !isFetchingZoneList && currentPage === 1) {
      setZones(zoneList.results);
    }
  }, [zoneList, isFetchingZoneList, currentPage]);

  const selectZoneAndScrollTop = (zone: IGetZone | null) => {
    if (selectedZoneId === zone?.id) {
      setSelectedZoneId(null);
    } else {
      setSelectedZoneId(zone?.id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const selectedZone = zones.find((zn: IGetZone) => zn.id === selectedZoneId);

  const loadMore = async () => {
    setCurrentPage(currentPage + 1);
    try {
      filters.page = currentPage + 1;
      const previousData = zones;
      const newResults = await refetch();
      setZones([...(previousData || []), ...(newResults.data?.results || [])]);
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  return (
    <>
      <PageHeader title={t('zones')} onCreate={() => navigate(urls.zones.create)} createLabel={t('create_zone')} />
      <Stack flexDirection="row" gap={2}>
        <Stack gap={1} flex={1}>
          <StyledStack gap="6px">
            {zones?.map((zone) => (
              <Card
                key={zone.id}
                onClick={() => selectZoneAndScrollTop(zone)}
                variant={selectedZone?.id === zone.id ? 'outlined' : 'elevation'}
              >
                <CardContent sx={{ padding: 2, ':last-child': { padding: 2, cursor: 'pointer' } }}>
                  <Typography mb="6px">{zone.name}</Typography>
                  <Stack direction="row" gap="6px" flexWrap="wrap">
                    {zone?.nais?.map((nai) => <UavTypeIconWithText key={nai.id} name={nai.name} />)}
                  </Stack>
                  {zone.missions && zone.missions?.length > 0 ? <Divider style={{ marginTop: '10px' }} /> : null}
                  {zone.missions?.map((mission) => (
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                      key={mission.id}
                    >
                      <Stack flexDirection="row" alignItems="center" gap="5px">
                        <MissionTypeIcon type={mission.type} />
                        <Typography variant="body2">{mission.mission_key || mission.name}</Typography>
                        <StatusLabel currentStatus={mission.status} id={mission.id} type="mission" size="sm" />
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <UavTypeIcon type={mission.crew?.uav[0]?.type} />
                        <Typography variant="body2" ml="5px">
                          {mission.crew?.name}
                        </Typography>
                        <Typography variant="body2">&nbsp;·&nbsp;{mission.crew?.uav[0].name}</Typography>
                      </Stack>
                    </Stack>
                  ))}
                </CardContent>
              </Card>
            ))}
          </StyledStack>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={loadMore}
              disabled={isFetchingZoneList || currentPage >= (zoneList?.pageCount || 0)}
              className="footer-button"
            >
              {t('loadMore')}
            </Button>
          </Stack>
        </Stack>
        <Hidden mdDown>
          {selectedZone && (
            <Stack gap={1} flex={1}>
              <ZoneDetailsSidebar selectedZone={selectedZone} selectZoneAndScrollTop={selectZoneAndScrollTop} />
            </Stack>
          )}
        </Hidden>
        <Hidden mdUp>
          {selectedZone && (
            <Dialog
              open={!!selectedZone}
              TransitionComponent={Transition}
              fullScreen
              sx={{
                marginTop: '30px',
                '& .MuiDialog-paper': {
                  borderRadius: '20px',
                },
              }}
            >
              <ZoneDetailsSidebar selectedZone={selectedZone} selectZoneAndScrollTop={selectZoneAndScrollTop} />
            </Dialog>
          )}
        </Hidden>
      </Stack>
    </>
  );
}

export default Zones;
