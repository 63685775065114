import { TMissionStatus } from 'types/mission';

const uavTypes = ['recon', 'strike'];

export const missionStatus = {
  PLANNED: 'planned',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  NOT_COMPLETED: 'not_completed',
  SUSPENDED: 'suspended',
};

const missionStatuses: TMissionStatus[] = [
  missionStatus.PLANNED as TMissionStatus,
  missionStatus.IN_PROGRESS as TMissionStatus,
  missionStatus.COMPLETED as TMissionStatus,
  missionStatus.NOT_COMPLETED as TMissionStatus,
  missionStatus.SUSPENDED as TMissionStatus,
];

export { uavTypes, missionStatuses };
