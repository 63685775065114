import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMission } from 'types/mission';

interface MissionsState {
  list: IMission[];
}

const initialState: MissionsState = {
  list: [],
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setMissions: (state, action: PayloadAction<IMission[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setMissions } = counterSlice.actions;

export default counterSlice.reducer;
