import { useMutation, useQueryClient } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useDeleteNAI = (onSuccessCallback: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<void, Error, string>({
    mutationKey: ['delete-nai'],
    mutationFn: async (naiId: string) => {
      await api.delete(apiURLs.nais.delete(naiId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
      toast.success(t('nai_deleted'));
      if (onSuccessCallback) onSuccessCallback();
    },
    onError: (err: any) => {
      toast.error(err.response?.data?.error || t('failed_to_delete_nai'));
    },
  });
};

export default useDeleteNAI;
