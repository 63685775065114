import React from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  items: string[];
  selected: string;
  onChange: (_value: string) => void;
  hideLabel?: boolean;
  label?: string;
  count?: number;
}

export default function GroupBy({ items, selected, onChange, hideLabel = false, label = 'display', count = 0 }: Props) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" mb={1}>
      {!hideLabel && (
        <Typography color="secondary" pr="18px">
          {t(label)}
        </Typography>
      )}
      <Tabs value={selected}>
        {items.map((item, idx) => (
          <Tab
            key={item}
            label={count > 0 && idx === items.length - 1 ? `${t(item)} (${count})` : t(item)}
            value={item}
            onClick={() => onChange(item)}
          />
        ))}
      </Tabs>
    </Stack>
  );
}
