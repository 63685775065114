import React from 'react';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { missionStatus } from 'constants/mission';
import { TMissionStatus } from 'types/mission';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme';

interface IProps {
  status: TMissionStatus;
  onClick: (_status: TMissionStatus) => void;
}

export const StatusWorkflow = {
  [missionStatus.PLANNED]: [
    {
      text: 'start_mission',
      color: colors.brand.b3,
      nextStatus: missionStatus.IN_PROGRESS,
    },
  ],
  [missionStatus.IN_PROGRESS]: [
    {
      text: 'finish',
      color: colors.grayscale.b5,
      nextStatus: missionStatus.COMPLETED,
    },
    {
      text: 'suspend',
      color: colors.system.yellow1,
      nextStatus: missionStatus.SUSPENDED,
    },
  ],
};

function MissionActionButton({ status, onClick }: IProps) {
  const { t } = useTranslation();
  const workflow = StatusWorkflow[status];
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  if (!workflow) return null;
  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={1} width="100%">
      {workflow.map((w) => (
        <Button
          variant="contained"
          onClick={() => onClick(w.nextStatus as TMissionStatus)}
          sx={{
            width: '100%',
            backgroundColor: `${w.color}`,
            border: 'none',
            '&:hover': {
              backgroundColor: `${w.color} !important`,
            },
          }}
        >
          {t(w.text)}
        </Button>
      ))}
    </Stack>
  );
}

export default MissionActionButton;
