import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  styled,
  Typography,
  Button,
  IconButton,
  Hidden,
  Dialog,
  Pagination,
  PaginationItem,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { urls } from 'router';

import { IMission, IMissionsFilterDto } from 'types/mission';
import { colors } from 'theme';

import Search from 'components/search';
import { Transition } from 'components/header/mobileMenu';
import MissionDetailsSidebar from '../components/missionDetailsSidebar';
import MissionGroup from '../components/missionGroup';
import MissionFilters from '../components/missionFilters';
import MissionFilterChips from '../components/missionFilterChips';
import MissionFilterBadge from '../components/missionFilterBadge';

import { extendMissionsWithZones, getFilters } from './utils';
import { useZones, useCrews, useMissions } from '../services/hooks';

const StyledArrowBackIcon = styled(ArrowBackIcon)({
  color: colors.grayscale.g2,
  border: `2px solid ${colors.grayscale.b3}`,
  borderRadius: '50%',
  padding: '10px',
});

const CustomPaginationItem = styled(PaginationItem)({
  '&.Mui-selected': {
    backgroundColor: colors.grayscale.b5,
    borderRadius: '24px',
  },
  fontSize: '14px',
  color: colors.grayscale.w0,
  height: '36px',
  minWidth: '25px',
  padding: '5px',
  margin: '0 2px',
});

function ArchiveMission() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');

  const [missions, setMissions] = useState<IMission[]>([]);
  const [selectedMissionId, setSelectedMissionId] = useState<string | null>();
  const [filters, setFilters] = useState({ ...getFilters(), groupedBy: 'finished' });
  const [isFiltersVisible, setFilterVisibility] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [filterCount, setFilterCount] = useState<number>(0);

  const { data: zones = [] } = useZones();
  const { data: crews = [] } = useCrews();

  const {
    data: missionsData,
    isFetching: isMissionsFetching,
    refetch: refetchMissions,
  } = useMissions({ search, limit: 6, filters, page: currentPage });

  useEffect(() => {
    if (missionsData?.results && !isMissionsFetching) {
      setMissions(extendMissionsWithZones(missionsData.results, zones));
    }
  }, [missionsData, zones, isMissionsFetching]);

  useEffect(() => {
    setPageCount(missionsData?.pageCount || 1);
  }, [missionsData]);

  const onSearch = (text: string) => {
    searchParams.set('search', text || '');
    setSearchParams(searchParams);
  };

  const applyFilters = () => {
    refetchMissions();
  };

  const selectMissionAndScrollTop = (mission: IMission) => {
    if (selectedMissionId === mission.id) {
      setSelectedMissionId(null);
    } else {
      setSelectedMissionId(mission.id);
      const element = document.getElementById('sidebar-info');
      element?.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  const selectedMission = missions.find((m: IMission) => m.id === selectedMissionId);

  const updateFilter = (filter: IMissionsFilterDto) => {
    setFilters(filter);
    localStorage.setItem('filters', JSON.stringify(filter));
    applyFilters();
  };

  return (
    <Stack sx={{ mb: '70px' }}>
      <Stack flexDirection="row" alignItems="center" gap="20px" mt="26px" mb="15px">
        <IconButton onClick={() => navigate(urls.missions.landing)} size="small" sx={{ padding: '0' }}>
          <StyledArrowBackIcon />
        </IconButton>
        <Typography variant="h1">{t('archived')}</Typography>
        <Search onSearch={onSearch} defaultValue={search} />
      </Stack>

      <Stack flexDirection="row" alignItems="center" justifyContent="start" my="5px" gap={2}>
        <Button
          sx={{ height: '36px', width: filterCount > 0 ? '113px' : '89px', padding: '9 12px' }}
          onClick={() => setFilterVisibility(true)}
          variant="outlined"
          color="primary"
          size="small"
        >
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1"> {t('filters')} </Typography>
            {filterCount > 0 && <MissionFilterBadge filterCount={filterCount} />}
          </Stack>
        </Button>
        <MissionFilterChips
          filters={filters}
          deleteFilterChip={(f: IMissionsFilterDto) => updateFilter(f)}
          onSetFilterCount={(count) => setFilterCount(count)}
        />
      </Stack>

      <Stack flexDirection="row">
        <Stack flex={1}>
          <MissionGroup
            key="finished"
            name="finished"
            missions={missions}
            selectMission={selectMissionAndScrollTop}
            selectedMissionId={selectedMission?.id}
            isFetching={isMissionsFetching}
            expanded={!!missions?.length}
          />
        </Stack>
        {selectedMission && (
          <Stack>
            <Hidden mdDown>
              <MissionDetailsSidebar mission={selectedMission} setSelectedMission={setSelectedMissionId} />
            </Hidden>
            <Hidden mdUp>
              <Dialog
                open={!!selectedMission}
                TransitionComponent={Transition}
                fullScreen
                sx={{
                  marginTop: '30px',
                  '& .MuiDialog-paper': {
                    borderRadius: '20px',
                  },
                }}
              >
                <MissionDetailsSidebar mission={selectedMission} setSelectedMission={setSelectedMissionId} />
              </Dialog>
            </Hidden>
          </Stack>
        )}
      </Stack>

      <MissionFilters
        open={isFiltersVisible}
        onClose={() => setFilterVisibility(false)}
        filters={filters}
        setFilters={(changedFilters) => setFilters({ ...changedFilters, groupedBy: 'finished' })}
        zones={zones}
        crews={crews}
        submitFilters={() => applyFilters()}
        archive
      />

      {pageCount > 1 && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '72px',
            mb: '40px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
            backgroundColor: colors.grayscale.b2,
          }}
        >
          <Pagination
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            count={pageCount}
            siblingCount={1}
            boundaryCount={1}
            hidePrevButton
            hideNextButton
          />
        </Stack>
      )}
    </Stack>
  );
}
export default ArchiveMission;
