import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Stack, Button, styled, Typography, TextField, Divider } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

import { defaultFilters, IMissionsFilterDto } from 'types/mission';
import { ICrew } from 'types/crew';
import { IGetZone } from 'types/zone';
import DroneIcon from 'assets/icons/drone';
import IconFrame from 'components/iconFrame';
import Autocomplete from 'components/autocomplete';
import { useSearchParams } from 'react-router-dom';
import { colors } from 'theme';

import MissionTypeButton from './missionTypeToggleButton';
import MissionStatusButton from './missionStatusToggleButton';

const IconButton = styled(Button)`
  color: ${colors.grayscale.g2};
  min-width: auto;
  &:hover {
    background-color: transparent;
  }
`;

const AutocompleteStyled = styled((props: any) => <Autocomplete {...props} />)`
  .MuiInputBase-root {
    background-color: ${colors.grayscale.b1};
    width: 454px;
    min-height: 46px;
    & span.MuiAutocomplete-tag {
      background-color: #1a322c;
      width: 60px;
      height: 36px;
      border-radius: 24px;
      padding: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-root {
    padding: 0 3px;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 14px;
  }
  .MuiChip-root {
    height: 36px;
    background-color: ${colors.brand.b3_2};
    border-radius: 24px;
  }
  .MuiAutocomplete-popper {
    background-color: green;
  }
  &.MuiAutocomplete-tag {
    background-color: ${colors.brand.b3_2};
  }
`;

interface IProps {
  open: boolean;
  onClose: () => void;
  setShowTargetsSelect?: () => void;
  filters?: IMissionsFilterDto;
  setFilters?: (_filters: any) => void;
  crews?: ICrew[];
  zones?: IGetZone[];
  submitFilters: () => void;
  archive?: boolean;
}

function MissionFilters({
  open,
  onClose,
  setFilters = () => {},
  filters = defaultFilters,
  zones = [],
  crews = [],
  submitFilters,
  archive = false,
}: IProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilter = (updatedFilters: IMissionsFilterDto) => {
    setFilters({ ...updatedFilters });
  };

  const onSubmitFilters = () => {
    localStorage.setItem('filters', JSON.stringify(filters));
    submitFilters();
    onClose();
  };

  const onResetFilters = () => {
    localStorage.removeItem('filters');
    setFilters(defaultFilters);
    searchParams.set('search', encodeURIComponent(''));
    setSearchParams(searchParams);
    window.location.reload();
  };

  const clearFilter = () => {
    localStorage.removeItem('filters');
    setFilters(defaultFilters);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack
        sx={{
          width: '502px',
          padding: '19px 25px 22px 25px',
          backgroundColor: colors.grayscale.b3,
          height: '100%',
          boxSizing: 'border-box',
        }}
        justifyContent="space-between"
      >
        <Stack>
          <Stack direction="row">
            <IconButton
              onClick={onClose}
              sx={{
                border: 'none',
                borderSizing: 'border-box',
                marginBottom: '15px',
                paddingRight: '30px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3"> {t('filters')} </Typography>
            <Button
              onClick={() => clearFilter()}
              sx={{ color: colors.grayscale.w1, width: '124px', height: '36px', borderColor: colors.system.red }}
              startIcon={<CloseIcon />}
            >
              {t('clear')}
            </Button>
          </Stack>

          <Typography sx={{ paddingTop: '20px' }} color={colors.grayscale.g2} variant="body2">
            {t('mission_type')}
          </Typography>

          <MissionTypeButton
            selectedType={filters.type}
            setSelectedType={(type) => updateFilter({ ...filters, type })}
          />

          {archive && (
            <Stack>
              <Typography sx={{ paddingTop: '18px' }} color={colors.grayscale.g2} variant="body2">
                {t('status')}
              </Typography>
              <MissionStatusButton
                selectedStatus={filters.status}
                setSelectedStatus={(status) => updateFilter({ ...filters, status })}
              />
            </Stack>
          )}

          <Typography sx={{ paddingTop: '18px' }} color={colors.grayscale.g2} variant="body2">
            {t('work_zones')}
          </Typography>

          <AutocompleteStyled
            id="filters-zone"
            sx={{ paddingTop: '4px' }}
            multiple
            options={zones}
            getOptionLabel={(option: IGetZone) => option.name || ''}
            onChange={(e: any, value: IGetZone[]) => {
              updateFilter({ ...filters, zones: value });
            }}
            value={filters.zones || []}
            isOptionEqualToValue={(option: IGetZone, value: IGetZone) => option.id === value.id}
            renderInput={(params: any) => <TextField placeholder={t('choose_zones')} {...params} />}
            popupIcon={<ExpandMoreIcon />}
            limitTags={2}
            slotProps={{
              paper: {
                sx: {
                  '& .MuiAutocomplete-listbox': {
                    '& .MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: 'transparent',
                      height: '46px',
                      '&:hover': {
                        backgroundColor: colors.base.gray[700],
                      },
                      '&.Mui-focused': {
                        backgroundColor: colors.base.gray[700],
                      },
                    },
                    '& .MuiAutocomplete-option': {
                      height: '46px',
                    },
                  },
                },
              },
            }}
            renderOption={(props: any, option: IGetZone) => {
              const { key, ...restProps } = props;
              return (
                <li key={key} {...restProps}>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Typography> {option?.name} </Typography>
                    {filters.zones?.find((zone) => zone.id === option.id) && (
                      <DoneIcon sx={{ color: colors.system.g1 }} />
                    )}
                  </Stack>
                </li>
              );
            }}
          />

          <Typography sx={{ paddingTop: '18px' }} color={colors.grayscale.g2} variant="body2">
            {t('crew')}
          </Typography>

          <AutocompleteStyled
            id="mission-crew"
            sx={{ paddingTop: '5px' }}
            multiple
            options={crews}
            getOptionLabel={(option: any) => {
              const selectedCrew = crews?.find((cr) => cr.id === option.id);
              return `${selectedCrew?.name} • ${selectedCrew?.uav ? selectedCrew?.uav[0]?.name : '-'}`;
            }}
            onChange={(e: Event, value: ICrew[]) => {
              updateFilter({ ...filters, crews: value });
            }}
            value={filters.crews || []}
            slotProps={{
              paper: {
                sx: {
                  '& .MuiAutocomplete-listbox': {
                    '& .MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: 'transparent',
                      height: '46px',
                      '&:hover': {
                        backgroundColor: colors.base.gray[700],
                      },
                      '&.Mui-focused': {
                        backgroundColor: colors.base.gray[700],
                      },
                    },
                    '& .MuiAutocomplete-option': {
                      height: '46px',
                    },
                  },
                },
              },
            }}
            renderOption={(props: any, option: any) => {
              const { key, ...restProps } = props;
              return (
                <li key={key} {...restProps}>
                  <Stack flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack flexDirection="row" alignItems="center" gap="5px">
                      <IconFrame backgroundColor="#454545">
                        <DroneIcon width="13px" height="13px" />
                      </IconFrame>
                      {option?.name} • {option?.uav ? option?.uav[0]?.name : '-'}
                    </Stack>
                    {filters.crews?.find((crew) => crew.id === option.id) && (
                      <DoneIcon sx={{ color: colors.system.g1 }} />
                    )}
                  </Stack>
                </li>
              );
            }}
            isOptionEqualToValue={(option: ICrew, value: ICrew) => option.id === value.id}
            renderInput={(params: any) => <TextField {...params} placeholder={t('select_crew')} />}
            popupIcon={<ExpandMoreIcon />}
            limitTags={2}
          />
        </Stack>

        <Stack gap={3}>
          <Divider
            sx={{
              marginX: '-25px',
              width: 'calc(100% + 50px)',
            }}
          />

          <Stack direction="row" gap={1}>
            <IconButton
              sx={{ width: '114px', height: '45px' }}
              onClick={() => onSubmitFilters()}
              variant="contained"
              color="primary"
            >
              {t('save')}
            </IconButton>
            <IconButton
              sx={{ width: '123px', height: '45px' }}
              onClick={() => onResetFilters()}
              variant="outlined"
              color="secondary"
            >
              {t('cancel')}
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default MissionFilters;
