import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Link, Stack, styled, Typography } from '@mui/material';

import { IMission } from 'types/mission';
import { getDefaultDate } from 'services/dayjs';
import { urls } from 'router';
import UavTypeIcon from 'components/uavTypeIcon';
import { TargetIcon } from 'assets/icons';
import { colors } from 'theme';
import StatusLabel from '../../../components/statusLabelDropdown';

const HistoryCard = styled(Card)(() => ({
  marginBottom: '8px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
}));

const ReportCard = styled(Card)(() => ({
  margin: '0 16px 16px',
  border: `1px solid ${colors.brand.b2}`,
  backgroundColor: colors.brand.b1,
}));

interface IProps {
  targetId: string;
  missions: IMission[];
}

function TargetHistory({ targetId, missions }: IProps) {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack flexDirection="row" gap="8px" alignItems="center" mb={2}>
        <TargetIcon fill={colors.grayscale.w1} width={24} height={24} />
        <Typography variant="body1">{t('target_history')}</Typography>
        <Typography variant="body1">{missions.length}</Typography>
      </Stack>
      {missions.map((mission) => (
        <HistoryCard key={mission.id}>
          <Stack p={2} pb={0}>
            <Link href={urls.missions.details(mission.id)}>
              <Typography variant="body2" mb={1}>
                {getDefaultDate(mission.due_date)}
              </Typography>
            </Link>
            <Stack mb={1} flexDirection="row">
              <StatusLabel type="mission" currentStatus={mission.status} id={targetId} />
            </Stack>
          </Stack>
          <Stack flexDirection="row" alignItems="center" p={2} pt={0}>
            <Typography variant="body2" pr="6px">
              {mission.crew.name} · {mission.crew.uav[0].name}
            </Typography>
            <UavTypeIcon type={mission.crew.uav[0].type} />
          </Stack>
          {mission.reports?.length && (
            <ReportCard>
              <Stack p={2}>
                <Typography variant="body1" mb={2}>
                  {t('report')}
                </Typography>
                <Typography variant="body1">{mission.reports[mission.reports.length - 1].text}</Typography>
              </Stack>
            </ReportCard>
          )}
        </HistoryCard>
      ))}
    </Stack>
  );
}

export default TargetHistory;
