import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Grid, Typography } from '@mui/material';
import { colors } from 'theme';

import { IGetZone } from 'types/zone';
import { IEventLogEntry } from 'types/eventlog';

import GbuAlert from '../components/gbuALert';
import ZoneAlertCard from '../components/zoneAlertCard';

import { useZones, useThreats } from '../services/hooks';

const splitZonesIntoRows = (zones: IGetZone[]) =>
  Array.from({ length: Math.ceil(zones.length / 4) }, (_, i) => zones.slice(i * 4, i * 4 + 4));

const getGridSize = (rowLength: number): number => {
  const sizeMap = [12, 6, 4, 3];
  return sizeMap[Math.min(rowLength - 1, sizeMap.length - 1)];
};

function HomeAirDefence() {
  const { t } = useTranslation();
  const { data: zoneList } = useZones();
  const { data: threatsList } = useThreats();

  const splitZones = useMemo(() => splitZonesIntoRows(zoneList?.results || []), [zoneList]);
  const gbuThreat = useMemo(
    () => threatsList?.some((threat: IEventLogEntry) => threat.entity_type === 'gbu'),
    [threatsList],
  );

  return (
    <Stack>
      {gbuThreat && <GbuAlert />}
      <Grid container spacing={2} sx={{ padding: `${gbuThreat ? '170px' : '24px'} 0px 0px` }}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '22px', fontWeight: 700 }} color={colors.grayscale.w0}>
            {t('zones')}
          </Typography>
        </Grid>
        {splitZones.map((splitZone) => (
          <Grid container item spacing="20px" key={splitZone[0].id}>
            {splitZone.map((zone) => (
              <Grid item xs={12} md={6} lg={getGridSize(splitZone.length)} key={zone.id}>
                <ZoneAlertCard
                  name={zone.name}
                  danger={!!threatsList?.some((threat: IEventLogEntry) => threat.entity_id === zone.id)}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default HomeAirDefence;
