import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { IGetMissionListResponse, IMissionsFilterDto } from 'types/mission';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

interface UseMissionsParams {
  search: string | null;
  limit: number;
  filters: IMissionsFilterDto;
  page: number;
}

export const useMissions = ({ search, limit, filters, page }: UseMissionsParams) => {
  const getFiltersWithUTCDate = (f: any) => {
    if (!f?.due_date) {
      return f;
    }
    return { ...f, due_date: dayjs(f.due_date).utc() };
  };

  return useQuery<IGetMissionListResponse, Error>({
    queryKey: ['mission-list', search, limit, filters, page],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.missions.list(search, page, limit, getFiltersWithUTCDate(filters)));
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch missions');
        throw err;
      }
    },
  });
};

export default useMissions;
