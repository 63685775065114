export const role = {
  GENERAL: 'general',
  CREW_MEMBER: 'crew_member',
  ISTAR: 'istar',
  BATTLE_CAPTAIN: 'battle_captain',
  ADMIN: 'admin',
  AIR_DEFENCE: 'air_defence',
};

export const roles = [role.GENERAL, role.CREW_MEMBER, role.ISTAR, role.BATTLE_CAPTAIN, role.ADMIN, role.AIR_DEFENCE];

export const status = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
