import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowUp } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Divider, Menu, MenuItem, Stack, styled, Typography } from '@mui/material';

import { useAuth } from 'features/login/authProvider';
import { colors } from 'theme';
import RoleSwitcher from './roleSwitcher';

const KHMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${colors.grayscale.b1};
    background-image: none;
    box-shadow: 0 10px 20px RGBA(0, 0, 0, 0.25);
    border-radius: 16px;
    margin-top: 9px;
    min-width: 220px;
    padding: 0;
  }
  & .MuiMenu-list {
    padding: 6px;
  }
  .MuiMenuItem-root:hover {
    background-color: ${colors.brand.b1};
    border-radius: 24px;
  }
`;

const StyledButton = styled(Button)(() => ({
  border: 'none',
  padding: '9px 8px',
  height: '36px',
  fontWeight: 400,
}));

function User() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <Stack>
      <Box display="flex" alignItems="center">
        <StyledButton
          sx={{ backgroundColor: open ? colors.grayscale.b5 : 'transparent' }}
          variant="contained"
          onClick={handleClick}
        >
          <Stack flexDirection="row" justifyContent="center" alignItems="center">
            {user?.username.replace(/^\w/, (c) => c.toUpperCase())}, {t(user?.currentRole || '')}
            <Stack justifyContent="center" alignItems="center" ml="6px">
              {open ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowUp fontSize="small" />}
            </Stack>
          </Stack>
        </StyledButton>
      </Box>
      <KHMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
      >
        <RoleSwitcher />
        <Divider />
        <MenuItem sx={{ height: '42px', marginTop: '5px' }} onClick={handleLogout} disableRipple>
          <Typography variant="body2" color="error">
            {t('log_out')}
          </Typography>
        </MenuItem>
      </KHMenu>
    </Stack>
  );
}

export default User;
