import React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { IMission } from 'types/mission';
import { getTime } from 'services/dayjs';
import { colors } from 'theme';
import UavTypeIcon from 'components/uavTypeIcon';
import MissionTypeIcon from 'components/missionTypeIcon';

import { first } from 'lodash';
import MissionCardTarget from './missionCardTarget';
import MissionCardZone from './missionCardZone';
import StatusLabel from '../../../components/statusLabelDropdown';

interface Props {
  mission: IMission;
  selectMission: (_mission: IMission) => void;
  selectedMissionId?: string;
}

function MissionCard({ mission, selectMission = () => {}, selectedMissionId }: Props) {
  return (
    <Stack mb="8px">
      <Card
        onClick={() => selectMission(mission)}
        variant="elevation"
        sx={{
          backgroundColor: selectedMissionId === mission.id ? colors.brand.b3_2 : colors.grayscale.b3,
          border: `1px solid ${selectedMissionId === mission.id ? colors.brand.b3 : colors.grayscale.b6}`,
        }}
      >
        <CardContent sx={{ padding: 2, ':last-child': { padding: 2, cursor: 'pointer' } }}>
          <Stack alignContent="space-between" gap="4px">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack
                padding="6.5px 4px"
                display="flex"
                gap="4px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ color: colors.grayscale.w0 }}>{mission.name}</Typography>
                {mission.finished && (
                  <StatusLabel currentStatus={mission.status} id={mission.id} type="mission" size="sm" />
                )}
                <MissionTypeIcon type={mission.type} />
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap="5px">
                <UavTypeIcon type={first(mission?.crew?.uav)?.type || ''} />
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
                  {mission.crew?.status === 'not_ready' ? (
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap="4px">
                      <Typography variant="body2" color={colors.system.red}>
                        {mission?.crew?.name}
                      </Typography>
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{ color: colors.system.red, fontSize: '.75rem' }}
                      />
                    </Stack>
                  ) : (
                    <Typography variant="body2">{mission?.crew?.name}</Typography>
                  )}
                  <Typography variant="body2">•</Typography>
                  {mission?.crew?.uav?.map((crew) => (
                    <Typography variant="body2" key={crew.id}>
                      {crew.name}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            {mission.type === 'strike' && <MissionCardTarget mission={mission} />}
            {mission.type === 'recon' && <MissionCardZone mission={mission} />}
            {!mission.finished && (
              <Stack flexDirection="row" gap="6px" padding="4px" alignItems="center">
                <AccessTimeIcon fontSize="small" style={{ color: colors.grayscale.g2 }} />
                <Typography sx={{ color: colors.grayscale.w0 }} variant="body2">
                  {getTime(mission.due_date)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default MissionCard;
