import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import api, { apiURLs } from 'services/api';

interface IUseReletedMission {
  id: string;
}

export const useRelatedMissions = ({ id }: IUseReletedMission) => {
  const { data, isLoading } = useQuery<any, Error>({
    queryKey: ['related-missions'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.zones.oneExtended(id));
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch related mission');
        throw err;
      }
    },
  });
  return { data, isLoading };
};

export default useRelatedMissions;
