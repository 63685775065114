import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';

import { urls } from 'router';
import ErrorIcon from 'assets/icons/error';

interface Props {
  setFileUploadedStatus: (_status: string) => void;
}

function TargetUploadError({ setFileUploadedStatus }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box m={3}>
      <Box flexDirection="column" justifyContent="center" alignItems="center" display="flex" gap="16px" mb="24px">
        <ErrorIcon />
        <Typography variant="h2">{t('something_went_wrong')}</Typography>
      </Box>
      <Stack direction="row" gap={2} mt={5} alignItems="center" justifyContent="center">
        <Button variant="contained" color="primary" size="large" onClick={() => navigate(urls.targets.landing)}>
          {t('open_targets')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => setFileUploadedStatus('upload_select')}
        >
          {t('upload_more')}
        </Button>
      </Stack>
    </Box>
  );
}

export default TargetUploadError;
