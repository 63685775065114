import React from 'react';
import { InputAdornment, TextField, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';

import { useTranslation } from 'react-i18next';

import { colors } from 'theme';

interface Props {
  onSearch: (_text: string) => void;
  defaultValue?: string | null;
}

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: ${colors.grayscale.b1};
    border: none;
    width: 120px;
    height: 46px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

function Search({ onSearch, defaultValue = '' }: Props) {
  const { t } = useTranslation();

  const debouncedOnSearch = React.useCallback((text: string) => debounce(() => onSearch(text), 500)(), [onSearch]);

  return (
    <StyledTextField
      size="small"
      placeholder={t('search')}
      defaultValue={defaultValue}
      onChange={(e) => debouncedOnSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" sx={{ color: colors.grayscale.g1, pl: '5px' }} />
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
}

export default Search;
