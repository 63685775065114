import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ITarget } from 'types/target';
import { IMission } from 'types/mission';

import { colors } from 'theme';

import TargetSign from '../../targets/components/targetSign';
import TargetCounts from '../../targets/components/targetCounts';

interface IProps {
  mission: IMission;
}

function MissionCardTarget({ mission }: IProps) {
  const { t } = useTranslation();

  const target = mission.target || [];

  const [visibleTargets, setVisibleTargets] = useState<ITarget[]>(target.slice(0, 2));
  const [hiddenCount, setHiddenCount] = useState<number>(0);

  useEffect(() => {
    setHiddenCount(target.length - visibleTargets.length);
  }, [visibleTargets, target.length]);

  return (
    <Stack gap={1} p="4px">
      {visibleTargets?.map((tg: ITarget) => (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={tg.id}>
          <Stack flexDirection="row" alignItems="center" gap="4px">
            <TargetSign sidc={tg.app6d_sidc} />
            <Typography sx={{ color: colors.grayscale.w0 }} variant="body2">
              {tg.name || tg.app6d_type}
            </Typography>
          </Stack>
          <TargetCounts photos={tg.images?.length} comments={tg.delta_comments?.length} />
        </Stack>
      ))}
      {hiddenCount > 0 && (
        <Typography
          onClick={() => setVisibleTargets(target)}
          sx={{ color: colors.grayscale.g2, width: '137px' }}
          variant="body2"
        >
          {`${t('show_more')} +${hiddenCount} ${t('targets').toLowerCase()}`}
        </Typography>
      )}
    </Stack>
  );
}

export default MissionCardTarget;
