import React, { useState } from 'react';

import { Button, Stack, Typography, styled, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { colors } from 'theme';
import { INAI } from 'types/zone';

import NAIDrawer from './naiDrawer';

import { useSubmitNAI, useUpdateNAI, useDeleteNAI } from '../services/hooks';

interface IProps {
  nais: INAI[];
  zoneId: string;
  refetchDetails: () => void;
}

const NaiCard = styled(Card)(() => ({
  marginLeft: '10px',
  marginBottom: '8px',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
  width: '547px',
  height: 'fit-content',
  marginTop: '10px',
}));

const IconButton = styled(Button)`
  padding: 2px 2px;
  color: ${colors.grayscale.g2};
  min-width: auto;
  border: none;
  &:hover {
    background-color: transparent;
  }
`;

function NAICard({ nais, zoneId, refetchDetails }: IProps) {
  const [selectedItem, setSelectedItem] = useState<INAI | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mode, setMode] = useState('create');

  const handleButtonClick = (item: INAI | null, m: string) => {
    setMode(m);
    setSelectedItem(item);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedItem(null);
  };

  const submitNAI = useSubmitNAI(zoneId, () => {
    refetchDetails();
    setSelectedItem(null);
  });

  const updateNAI = useUpdateNAI(() => {
    refetchDetails();
  });

  const deleteNAIMutate = useDeleteNAI(() => {
    refetchDetails();
  });

  const saveNai = (nai: any) => {
    setDrawerOpen(false);
    if (mode === 'create') {
      submitNAI.mutate({ ...nai });
    }
    if (mode === 'edit') {
      updateNAI.mutate({ ...nai });
    }
  };

  return (
    <Stack>
      <NaiCard>
        <Stack sx={{ padding: '20px 19px 20px 23px' }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '18px' }}>NAI {nais?.length}</Typography>
            <IconButton size="small">
              <AddIcon onClick={() => handleButtonClick(null, 'create')} />
            </IconButton>
          </Stack>
          <Stack sx={{ marginTop: '23px' }}>
            {nais?.map((nai) => (
              <Stack key={nai.id} flexDirection="row" gap={3} justifyContent="space-between" alignItems="center">
                <Typography variant="body1">{nai?.name} </Typography>
                <Stack flexDirection="row" alignItems="center" gap="8px">
                  <IconButton>
                    <EditIcon onClick={() => handleButtonClick(nai, 'edit')} fontSize="small" />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon onClick={() => deleteNAIMutate.mutate(nai.id)} fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </NaiCard>
      <NAIDrawer open={drawerOpen} nai={selectedItem} onClose={handleCloseDrawer} saveNai={saveNai} onMode={mode} />
    </Stack>
  );
}

export default NAICard;
