import React from 'react';
import { Skeleton, TableBody, TableCell, TableHead, TableRow, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IRelatedMissions } from 'types/zone';
import StatusLabelDropdown from 'components/statusLabelDropdown';
import KHTable from 'components/khTable';

interface Props {
  rows: IRelatedMissions[];
  isLoading?: boolean;
}

function RelatedMissionTable({ rows, isLoading = false }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableHeaders: { label: string; align: 'center' | 'left' | 'right' | 'justify' | 'inherit' }[] = [
    { label: 'mission_name', align: 'left' },
    { label: 'crew', align: 'left' },
    { label: 'last_update', align: 'center' },
    { label: 'status', align: 'right' },
  ];

  const renderSkeletonRows = () =>
    Array.from({ length: 5 }).map((_, rowIdx) => (
      /* eslint-disable-next-line */
      <TableRow key={rowIdx}>
        {tableHeaders.map((__, colIdx) => (
          /* eslint-disable-next-line */
          <TableCell key={colIdx}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ));

  const getColorByStatus = (status: string) => (status === 'not_ready' ? 'error' : '');

  const renderRows = () =>
    rows.map(({ id, name, crew, uav, crewStatus, lastUpdate, status }) => (
      <TableRow key={id} onClick={() => navigate(`/missions/${id}`)} hover>
        <TableCell component="th" scope="row">
          <Typography variant="body1">{name}</Typography>
        </TableCell>
        <TableCell>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography color={getColorByStatus(crewStatus)} variant="body1">
                {crew}
              </Typography>
              <Typography color={getColorByStatus(crewStatus)} variant={crewStatus === 'not_ready' ? 'h6' : 'body1'}>
                •
              </Typography>
              <Typography variant="body1">{uav}</Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1">{t(lastUpdate)}</Typography>
        </TableCell>
        <TableCell align="right">
          <StatusLabelDropdown currentStatus={status} id={id} type="mission" />
        </TableCell>
      </TableRow>
    ));

  return (
    <KHTable>
      <TableHead>
        <TableRow>
          {tableHeaders.map((header, idx) => (
            /* eslint-disable-next-line */
            <TableCell key={idx} align={header.align}>
              <Typography variant="body2">{t(header.label)}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{isLoading ? renderSkeletonRows() : renderRows()}</TableBody>
    </KHTable>
  );
}

export default RelatedMissionTable;
