import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Stack, styled, TextField, Typography } from '@mui/material';

import { colors } from 'theme';
import { getTextFieldErrorProps, IError } from 'services/helpers';
import { ILocation } from 'types/crew';

const FormCard = styled(Card)(() => ({
  border: 'none',
}));

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
}));

interface Props {
  location: ILocation;
  setLocation: (_location: ILocation) => void;
  errors: IError;
}

function LocationForm({ location, setLocation, errors }: Props) {
  const { t } = useTranslation();

  return (
    <Stack>
      <FormCard>
        <FormCardContent>
          <Grid container p={4}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('name')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder={t('enter_name')}
                    value={location?.name}
                    onChange={(e) => setLocation({ ...location, name: e.target.value })}
                    size="small"
                    {...getTextFieldErrorProps(errors, 'name', t)}
                  />
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('control_point_distance')}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder={t('add_coordinates')}
                    value={location?.control_point_distance}
                    onChange={(e) => setLocation({ ...location, control_point_distance: Number(e.target.value) })}
                    InputProps={{
                      endAdornment: <Typography variant="body2">m</Typography>,
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={7} />
                <Grid item xs={12} md={3} alignContent="center">
                  <Typography variant="h3">{t('details')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    placeholder={t('add_details')}
                    value={location?.description}
                    onChange={(e) => setLocation({ ...location, description: e.target.value })}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={3} />
              </Grid>
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
    </Stack>
  );
}

export default LocationForm;
