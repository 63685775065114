import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { urls } from 'router';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import api, { apiURLs } from 'services/api';
import FakeModal from 'components/fakeModal';
import { getValidationErrors, IError } from 'services/helpers';
import defaultLocation from 'defaults/location';
import { IGetLocationResponse, ILocation } from 'types/crew';
import { Container } from '@mui/material';
import LocationForm from '../components/locationForm';

const schema = yup
  .object({
    name: yup.string().required('name_required').min(1, 'name_too_short'),
  })
  .required();

function CreateLocation() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [location, setLocation] = React.useState<ILocation>(defaultLocation);
  const [errors, setErrors] = React.useState<IError>({});

  const { isLoading, data: details } = useQuery({
    enabled: !!id,
    queryKey: ['location-details', id],
    queryFn: (): Promise<ILocation> =>
      api
        .get(apiURLs.admin.location.details(id as string))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  const mutationCb = (locationRes: IGetLocationResponse, reset: () => void) => {
    reset();
    navigate(urls.admin.location.landing);
    toast.success(t(id ? 'locationUpdated' : 'locationCreated'));
    return locationRes;
  };

  const { mutate: submit, reset } = useMutation({
    mutationKey: ['submit-location'],
    mutationFn: () =>
      id
        ? api.patch(apiURLs.admin.location.patch(id as string), location).then(({ data }) => {
            mutationCb(data, reset);
          })
        : api
            .post(apiURLs.admin.location.create, location)
            .then(({ data }) => {
              mutationCb(data, reset);
            })
            .catch((err) => toast.error(t(err.response.data?.errorCode))),
  });

  React.useEffect(() => {
    if (details) {
      setLocation(details);
    }
  }, [details]);

  const validateAndSubmit = (l: Location) => {
    schema
      .validate(l, { abortEarly: false })
      .then(() => {
        setErrors({});
        submit();
      })
      .catch((err: ValidationError) => {
        setErrors(getValidationErrors(err));
      });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('create_location')}
        cancel={() => navigate(urls.admin.location.landing)}
        // @ts-ignore
        onSubmit={() => validateAndSubmit(location)}
        isLoading={isLoading}
      >
        <LocationForm location={location} setLocation={setLocation} errors={errors} />
      </FakeModal>
    </Container>
  );
}

export default CreateLocation;
