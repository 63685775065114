import { Button, styled } from '@mui/material';

import { colors } from 'theme';

export const StatusLabel = styled(Button)`
  width: auto;
  padding: 0 4px;
  border: none;
  color: ${colors.grayscale.g2};
  background-color: ${colors.grayscale.b5};
  &:hover {
    background-color: ${colors.grayscale.b5};
  }
  &:disabled {
    color: ${colors.grayscale.g2};
  }
  &.in_progress {
    color: #689cff;
    background-color: #233756;
  }
  &.operating {
    color: #47e657;
    background-color: #2f5623;
  }
  &.planned {
    color: #afafaf;
    background-color: #454545;
  }
  &.unplanned {
    color: #afafaf;
    background-color: #454545;
  }
  &.affected {
    color: #47e657;
    background-color: #2f5623;
  }
  &.completed {
    color: #47e657;
    background-color: #2f5623;
  }
  &.ready {
    color: #689cff;
    background-color: #233756;
  }
  &.not_affected {
    color: #ffb443;
    background-color: #564416;
  }
  &.suspended {
    color: #ffb443;
    background-color: #564416;
  }
  &.not_ready {
    color: #ff7d55;
    background-color: #562f23;
  }
  &.not_completed {
    color: #ff7d55;
    background-color: #562f23;
  }
`;

export default StatusLabel;
