import { ITarget } from 'types/target';
import { Card, Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { IMission } from '../../../types/mission';
import TargetStatus from './targetStatus';
import { urls } from '../../../router';
import TargetHistory from './targetHistory';
import { colors } from '../../../theme';

interface Props {
  target: ITarget;
}

const HistoryCard = styled(Card)(() => ({
  marginBottom: '8px',
  padding: '18px 18px 0',
  border: `1px solid ${colors.grayscale.b5}`,
  backgroundColor: colors.grayscale.b3,
}));

function TargetMissions({ target }: Props) {
  const completedMissions: IMission[] = [];
  const activeMissions: IMission[] = [];

  target.missions.forEach((m) => {
    if (m.finished) {
      completedMissions.push(m);
    } else {
      activeMissions.push(m);
    }
  });

  return (
    <>
      {activeMissions?.length > 0 && (
        <HistoryCard>
          <Stack flexDirection="row" gap="8px" alignItems="center" mb={2}>
            <Typography variant="body1">Активні місії</Typography>
            <Typography variant="body1">{activeMissions.length}</Typography>
          </Stack>
          {activeMissions.map((m) => (
            <Grid item xs={12} key={target.id}>
              <TargetStatus
                mission={m}
                statusType="mission"
                targetId={target.id}
                currentStatus={m.status}
                path={urls.missions.details(m.id)}
              />
            </Grid>
          ))}
        </HistoryCard>
      )}
      {completedMissions?.length > 0 && (
        <HistoryCard>
          <TargetHistory missions={completedMissions} targetId={target.id} />
        </HistoryCard>
      )}
    </>
  );
}

export default TargetMissions;
