import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, styled, Typography, Stack } from '@mui/material';
import { colors } from 'theme';

const CardBox = styled(Paper)(() => ({
  height: '240px',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  border: `1px solid ${colors.grayscale.b5}`,
  padding: '24px',
  boxSizing: 'border-box',
}));

interface IZoneAlertCard {
  name: string;
  danger: boolean;
}

function ZoneAlertCard({ name, danger }: IZoneAlertCard) {
  const { t } = useTranslation();
  return (
    <CardBox
      sx={{
        backgroundColor: danger ? colors.system.red : colors.grayscale.b3,
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 700, color: danger ? colors.grayscale.b2 : colors.grayscale.w0 }}>
        {name}
      </Typography>
      <Stack>
        <Typography
          sx={{ fontSize: '26px', fontWeight: 700, color: danger ? colors.grayscale.b2 : colors.grayscale.w0 }}
        >
          {danger ? t('danger_zone') : t('clear_zone')}
        </Typography>
      </Stack>
    </CardBox>
  );
}

export default ZoneAlertCard;
