import React from 'react';
import { Chip as MaterialChip, styled } from '@mui/material';
import { colors } from 'theme';

const StyledChip = styled(MaterialChip)`
  color: ${colors.grayscale.g2};
  border: 2px solid ${colors.grayscale.b5};
`;

export default function Chip(props: any) {
  return <StyledChip {...props} />;
}
