/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { io, Socket } from 'socket.io-client';
import { IGetMissionListResponse } from 'types/mission';
import { apiURLs } from 'services/api';
import { IGetTargetListResponse } from '../types/target';
import { IGetCrewListResponse } from '../types/crew';

export const messageTypesEnum = {
  MISSION_STATUS_UPDATE: 'MISSION_STATUS_UPDATE',
  MISSION_CREATED: 'MISSION_CREATED',
  TARGET_STATUS_UPDATE: 'TARGET_STATUS_UPDATE',
  CREW_STATUS_UPDATE: 'CREW_STATUS_UPDATE',
};

export default function useWebSockets(
  queryClient: QueryClient,
  messageTypes: string[],
  handler: ((_data: any) => void) | null = null,
) {
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const socket = io(apiURLs.baseURL as string, {
      auth: {
        token: localStorage.getItem('authToken'),
      },
    });

    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log('WebSocket connected');
    });

    socket.on('connect_error', (error) => {
      // eslint-disable-next-line no-console
      console.error('WebSocket connection error:', error);
    });

    socketRef.current = socket;

    return () => {
      if (socket.connected) {
        socket.close();
        // eslint-disable-next-line no-console
        console.log('WebSocket disconnected');
      }
    };
  }, []); // Empty dependency array to ensure this effect runs only once

  useEffect(() => {
    const handleMessage = (event: string) => {
      try {
        const eventData = JSON.parse(event);

        if (!messageTypes.includes(eventData.type)) {
          return;
        }

        if (handler) {
          handler(eventData);
        }

        if (eventData.type === messageTypesEnum.MISSION_STATUS_UPDATE) {
          queryClient
            .getQueryCache()
            .findAll({ queryKey: ['mission-list'] })
            .forEach(({ queryKey }) => {
              queryClient.setQueryData(
                queryKey,
                (missionsResp: IGetMissionListResponse) =>
                  missionsResp && {
                    ...missionsResp,
                    results: missionsResp.results.map((m) => {
                      if (m.id === eventData.data.id) {
                        return {
                          ...m,
                          status: eventData.data.status,
                          reports: [...(m.reports || []), eventData.data?.data?.report || null].filter(Boolean),
                        };
                      }
                      return m;
                    }),
                  },
              );
            });
        } else if (eventData.type === messageTypesEnum.MISSION_CREATED) {
          const { mission } = eventData.data.data;
          if (mission) {
            queryClient
              .getQueryCache()
              .findAll({ queryKey: ['mission-list'] })
              .forEach(({ queryKey }) => {
                queryClient.setQueryData(
                  queryKey,
                  (missionsResp: IGetMissionListResponse) =>
                    missionsResp && {
                      ...missionsResp,
                      results: [mission, ...missionsResp.results],
                    },
                );
              });
          }
        } else if (eventData.type === messageTypesEnum.TARGET_STATUS_UPDATE) {
          queryClient
            .getQueryCache()
            .findAll({ queryKey: ['target-list'] })
            .forEach(({ queryKey }) => {
              queryClient.setQueryData(
                queryKey,
                (targetsResp: IGetTargetListResponse) =>
                  targetsResp && {
                    ...targetsResp,
                    results: targetsResp.results.map((tg) => {
                      if (tg.id === eventData.data.id) {
                        return {
                          ...tg,
                          status: eventData.data.status,
                          missions:
                            tg.missions &&
                            tg.missions.map((m) => {
                              if (m.id === eventData.data.data?.mission?.id) {
                                return {
                                  ...m,
                                  status: eventData.data.data?.mission?.status,
                                };
                              }
                              return m;
                            }),
                        };
                      }
                      return tg;
                    }),
                  },
              );
            });
        } else if (eventData.type === messageTypesEnum.CREW_STATUS_UPDATE) {
          queryClient
            .getQueryCache()
            .findAll({ queryKey: ['crew-list'] })
            .forEach(({ queryKey }) => {
              queryClient.setQueryData(
                queryKey,
                (crewResp: IGetCrewListResponse) =>
                  crewResp && {
                    ...crewResp,
                    results: crewResp.results.map((cr) => {
                      if (cr.id === eventData.data.id) {
                        return {
                          ...cr,
                          status: eventData.data.status,
                          reports: [...(cr.reports || []), eventData.data?.data?.report || null].filter(Boolean),
                        };
                      }
                      return cr;
                    }),
                  },
              );
            });
        }
        queryClient
          .getQueryCache()
          .findAll({ queryKey: ['mission-list'] })
          .forEach(({ queryKey }) => {
            queryClient.setQueryData(
              queryKey,
              (missionsResp: IGetMissionListResponse) =>
                missionsResp && {
                  ...missionsResp,
                  results: missionsResp.results.map((m) => {
                    if (m.crew.id === eventData.data.id) {
                      return {
                        ...m,
                        crew: {
                          ...m.crew,
                          status: eventData.data.status,
                          reports: [...(m.crew.reports || []), eventData.data?.data?.report || null].filter(Boolean),
                        },
                      };
                    }
                    return m;
                  }),
                },
            );
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error handling message:', error);
      }
    };

    const socket = socketRef.current;
    if (socket) {
      socket.on('message', handleMessage);
    }

    return () => {
      if (socket) {
        socket.off('message', handleMessage);
      }
    };
  }, [queryClient, messageTypes, handler]);
}
