import React from 'react';
import { Card, CardContent, Divider, Grid, Stack, styled } from '@mui/material';
import { colors } from 'theme';
import { IError } from 'services/helpers';
import User from '../services/model';
import PasswordForm from './passwordForm';
import UserFields from './userFields';

const FormCard = styled(Card)(() => ({
  border: 'none',
}));

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  backgroundColor: `${colors.grayscale.b2}`,
  border: 'none',
}));

interface Props {
  user: User | null;
  setUser: (_props: any) => void;
  errors: IError;
}

export default function CreateUserForm({ user, setUser, errors }: Props) {
  const updateUser = (field: string, value: string) => {
    setUser({ ...user, [field]: value });
  };

  return (
    <Stack>
      <FormCard>
        <FormCardContent>
          <Grid container p={4} spacing={3}>
            <Grid item xs={12} md={12} lg={8}>
              <UserFields user={user} setUser={setUser} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item md={12} lg={8}>
              <PasswordForm
                passwords={{ password: user?.password || '', confirm_password: user?.confirm_password || '' }}
                setPasswords={updateUser}
                errors={errors}
              />
            </Grid>
          </Grid>
        </FormCardContent>
      </FormCard>
    </Stack>
  );
}
