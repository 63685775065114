import React from 'react';
import { Box, Button, Hidden, Paper, Popover, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme';
import { ICrew, TCrewStatus } from 'types/crew';
import { crewStatus } from 'features/crews/services/constants';
import CrewStatusConfirmation from 'features/pilot/components/crewStatusConfirmation';
import { useUpdateCrewStatus } from '../../crews/services/hooks';

const StyledButton = styled(Button)<{ isMobile: boolean }>`
  padding: 8px 16px;
  color: #689cff;
  background-color: #233756;
  border: 1px solid #689cff;
  min-width: ${({ isMobile }) => (isMobile ? '200px' : 0)};
  width: 100%;
  :hover {
    background-color: #233756;
  }
  :disabled {
    color: #689cff;
  }
  &.not_ready {
    color: #ff7d55;
    border: 1px solid #ff7d55;
    background-color: #562f23;
  }
`;

const StyledPaper = styled(Paper)<{ isMobile: boolean }>`
  padding: 10px;
  background-color: ${colors.grayscale.b1};
  border: 1px solid ${colors.grayscale.b3};
  min-width: ${({ isMobile }) => (isMobile ? '300px' : '200px')};
`;

interface Props {
  crew: ICrew;
  options: TCrewStatus[];
}

function CrewStatusSwitcher({ crew, options }: Props) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const updateCrewStatus = useUpdateCrewStatus(crew);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onStatusChange = (status: TCrewStatus) => {
    if (status === crewStatus.NOT_READY) {
      setConfirmationOpen(true);
      return;
    }
    updateCrewStatus.mutate({ id: crew.id, status });
  };

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Hidden mdDown>
        <Typography variant="h2" fontWeight={600} color={colors.grayscale.g2}>
          {crew.name}
        </Typography>
      </Hidden>
      <Box width="100%">
        <StyledButton className={`${crew.status}`} onClick={handleClick} isMobile={isMobile}>
          {t(`status_crew_${crew.status}`)}
        </StyledButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ marginTop: '5px' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StyledPaper isMobile={isMobile}>
            {options?.map((option) => (
              <Stack key={option} alignItems="flex-start" mb="10px">
                <StyledButton
                  isMobile={isMobile}
                  onClick={() => {
                    onStatusChange(option);
                    handleClose();
                  }}
                  className={option}
                >
                  {t(`status_crew_${option}`)}
                </StyledButton>
              </Stack>
            ))}
          </StyledPaper>
        </Popover>
      </Box>
      <CrewStatusConfirmation
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onSave={(report: string) =>
          updateCrewStatus.mutate({
            id: crew.id,
            status: crewStatus.NOT_READY,
            report,
          })
        }
      />
    </Stack>
  );
}

export default CrewStatusSwitcher;
