import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { Container } from '@mui/material';

import api, { apiURLs } from 'services/api';
import FakeModal from 'components/fakeModal';
import { urls } from 'router';

import { getValidationErrors, IError } from 'services/helpers';
import { uavTypes } from 'constants/mission';
import { IUAV, IUAVType } from 'types/uav';
import UAVForm from '../components/uavForm';

const defaultUAVValue: IUAV = {
  name: '',
  type: uavTypes[0] as IUAVType,
  range: 0,
  description: '',
};

const schema = yup
  .object({
    name: yup.string().required('name_required').min(1, 'name_too_short'),
    range: yup.number().min(0, 'range_too_low'),
    description: yup.string(),
  })
  .required();

export default function CreateUAV() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [uav, setUAV] = React.useState<IUAV>(defaultUAVValue);
  const [errors, setErrors] = React.useState<IError>({});

  const { isLoading, data: details } = useQuery({
    enabled: !!id,
    queryKey: ['uav-details', id],
    queryFn: (): Promise<IUAV> =>
      api
        .get(apiURLs.admin.uavs.details(id as string))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  const mutationCb = (res: any, reset: any) => {
    reset();
    navigate(urls.admin.uav.landing);
    toast.success(t(id ? 'uavUpdated' : 'uavCreated'));
    return res.data;
  };

  const { mutate: submit, reset } = useMutation({
    mutationKey: ['submit-uav'],
    mutationFn: () =>
      id
        ? api.patch(apiURLs.admin.uavs.patch(id as string), uav).then((res) => {
            mutationCb(res, reset);
          })
        : api
            .post(apiURLs.admin.uavs.create, uav)
            .then((res) => {
              mutationCb(res, reset);
            })
            .catch((err) => toast.error(t(err.response.data?.errorCode))),
  });

  React.useEffect(() => {
    if (details) {
      setUAV(details);
    }
  }, [details]);

  const validateAndSubmit = (u: IUAV) => {
    schema
      .validate(u, { abortEarly: false })
      .then(() => {
        setErrors({});
        submit();
      })
      .catch((err: ValidationError) => {
        setErrors(getValidationErrors(err));
      });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('create_UAV')}
        cancel={() => navigate(urls.admin.uav.landing)}
        // @ts-ignore
        onSubmit={() => validateAndSubmit(uav)}
        isLoading={isLoading}
      >
        <UAVForm uav={uav} setUAV={setUAV} errors={errors} />
      </FakeModal>
    </Container>
  );
}
