import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Divider, Grid, IconButton, Link, Stack, styled, Typography } from '@mui/material';

import { getDefaultDate } from 'services/dayjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { IGetMissionListResponse, IMission, TMissionStatus } from 'types/mission';
import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import { colors } from 'theme';
import UavTypeIcon from 'components/uavTypeIcon';
import MissionTypeIcon from 'components/missionTypeIcon';
import { missionStatus, missionStatuses } from 'constants/mission';
import EndMissionConfirmation from 'components/endMissionConfirmation';
import ReasonAlert from 'components/reasonAlert';
import { useNavigate } from 'react-router-dom';
import { last } from 'lodash';
import { INAI } from 'types/zone';

import TargetCardAccordion from './targetCardAccordion';
import StatusLabel from '../../../components/statusLabelDropdown';
import { crewStatus } from '../../crews/services/constants';
import MissionReport from '../../../components/missionReport';
import FinishMissionButton from './FinishMissionButton';
import EditButton from '../../../components/buttons/editButton';
import MissionCardZone from './missionCardZone';
import MissionAudit from './missionAudit';
import NAIMap from '../../zones/components/naiMap';

interface IProps {
  mission: IMission;
  setSelectedMission: (_id: string | null) => void;
}

const DetailsCard = styled(Card)(() => ({
  padding: '30px',
  border: `1px solid ${colors.grayscale.b6}`,
  position: 'sticky',
  top: '8px',
  overflow: 'scroll',
  backgroundColor: colors.grayscale.b3,
  width: '650px',
}));

const FakeButton = styled(Button)`
  border: none;
  padding: 0;
  margin: 0;
  color: ${colors.grayscale.w1};
  font-weight: 400;
  text-align: left;
  &:hover {
    background-color: transparent;
  }
`;

async function copyTextToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
  toast.success('Скопійовано в буфер');
}

function MissionDetailsSidebar({ mission: missionDefault, setSelectedMission }: IProps) {
  const [endMissionConfirmation, setEndMissionConfirmation] = React.useState(false);
  const [suspendMissionConfirmation, setSuspendMissionConfirmation] = React.useState(false);
  const [endMissionStatus, setEndMissionStatus] = React.useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [mission, setMission] = React.useState<IMission>(missionDefault);

  const { data: missionData } = useQuery({
    queryKey: ['mission-details', missionDefault.id, missionDefault.status, missionDefault.checklist],
    queryFn: (): Promise<IMission> =>
      api
        .get(apiURLs.missions.details(missionDefault.id))
        .then((res) => res.data)
        .catch(() => mission),
  });

  React.useEffect(() => {
    if (missionData) setMission({ ...missionDefault, ...missionData });
  }, [missionData, missionDefault]);

  const { crew } = mission;
  const controlPointDistance = crew?.location?.control_point_distance || 0;

  const { mutate: submit, reset } = useMutation({
    mutationKey: ['submit-status'],
    mutationFn: (m: any) =>
      api
        .patch(apiURLs.missions.updateStatus(mission.id), m)
        .then(({ data }) => {
          reset();
          toast.success(t('mission_status_updated'));
          return data;
        })
        .catch((err) => toast.error(err.response.data?.error)),
    onSuccess: (updatedMission: IMission) =>
      queryClient
        .getQueryCache()
        .findAll({ queryKey: ['mission-list'] })
        .forEach((x) => {
          queryClient.setQueryData(x.queryKey, (missions: IGetMissionListResponse) => ({
            ...missions,
            results: missions.results.map((m) => {
              if (m.id === updatedMission.id) {
                return updatedMission;
              }
              return m;
            }),
          }));
        }),
  });

  function onStatusClick(status: TMissionStatus) {
    if (mission.status !== status) {
      if ([missionStatus.COMPLETED, missionStatus.NOT_COMPLETED].includes(status)) {
        setEndMissionStatus(status);
        setEndMissionConfirmation(true);
      } else if (status === missionStatus.SUSPENDED) {
        setEndMissionStatus(status);
        setSuspendMissionConfirmation(true);
      } else {
        submit({ status, report: '' });
      }
    }
  }

  function handleEndMissionSubmit(status: string, report: string) {
    setEndMissionConfirmation(false);
    setSuspendMissionConfirmation(false);
    submit({ status, report });
  }

  return (
    <Stack gap={1} flex={1}>
      <DetailsCard id="sidebar-info" sx={{ height: `calc(100vh - ${mission.finished ? '210px' : '144px'})` }}>
        <Stack flexDirection="row" justifyItems="center" justifyContent="space-between" mb={2}>
          <IconButton
            aria-label="close"
            onClick={() => setSelectedMission(null)}
            style={{ padding: '0', color: colors.grayscale.g2 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" flex={1}>
          <Stack flex={1}>
            <Link href={urls.missions.details(mission?.id)}>
              <Typography variant="body2" color={colors.grayscale.g1} mb={1}>
                {t('mission_details')}
              </Typography>
            </Link>
            <Stack flexDirection="row" justifyContent="space-between" display="flex" alignItems="center">
              <Link href={urls.missions.details(mission?.id)}>
                <Typography variant="h1">{mission.name}</Typography>
              </Link>
              {!mission.finished && <EditButton onClick={() => navigate(urls.missions.edit(mission.id))} />}
            </Stack>
          </Stack>
        </Stack>
        <MissionAudit mission={mission} />
        <Box mt={2}>
          <FinishMissionButton mission={mission} onClose={() => setSelectedMission(null)} />
        </Box>
        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={6}>
            <Typography variant="body2" mb={1}>
              {t('type')}
            </Typography>
            <Stack flexDirection="row" alignItems="center" gap="6px">
              <Typography variant="body1">{t(mission.type)}</Typography>
              <MissionTypeIcon type={mission.type} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" mb={1}>
              {t('status')}
            </Typography>
            <StatusLabel
              type="mission"
              currentStatus={mission.status}
              options={missionStatuses}
              id={mission.id}
              onClick={(status: TMissionStatus) => onStatusClick(status)}
              selectable={!mission.finished}
            />
          </Grid>
          {mission.type === 'recon' && (
            <>
              <Grid item xs={6} mb={2}>
                <Typography variant="body2" mb={1}>
                  {t('ID')}
                </Typography>
                <Typography variant="body1">
                  <FakeButton onClick={() => copyTextToClipboard(mission.mission_key || '')}>
                    {mission.mission_key || 'Ключ відсутній'}
                  </FakeButton>
                </Typography>
              </Grid>
              {mission.work_area && (
                <Grid item xs={6} mb={2}>
                  <Typography variant="body2" mb={1}>
                    {t('work_area')}
                  </Typography>
                  <Typography variant="body1">{mission.work_area || 'Ключ відсутній'}</Typography>
                </Grid>
              )}
            </>
          )}
          {mission.status === missionStatus.NOT_COMPLETED && (
            <Grid item xs={12}>
              <ReasonAlert reason={last(mission.reports)} color="red" title={t('reason_mission_not_completed')} />
            </Grid>
          )}
          {mission.status === missionStatus.SUSPENDED && (
            <Grid item xs={12}>
              <ReasonAlert reason={last(mission.reports)} color="yellow" title={t('reason_mission_suspended')} />
            </Grid>
          )}
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>
              {t('created')}
            </Typography>
            <Typography variant="body1">{getDefaultDate(mission.created_at)}</Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>
              {t('due_date')}
            </Typography>
            <Typography variant="body1">{getDefaultDate(mission.due_date)}</Typography>
          </Grid>
        </Grid>
        {mission.description && (
          <>
            <Divider style={{ marginBottom: '16px' }} />
            <Typography variant="h3" mb={2}>
              {t('description')}
            </Typography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6} mb={2}>
                <Typography variant="body1">{mission.description}</Typography>
              </Grid>
            </Grid>
          </>
        )}
        {mission.zones?.length && mission.zones?.length > 0 ? (
          <>
            <Divider style={{ marginBottom: '16px' }} />
            <Typography variant="h3" mb={2}>
              {t('zones')}
            </Typography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} mb={2}>
                <MissionCardZone mission={mission} />
              </Grid>
            </Grid>
          </>
        ) : null}

        {(mission.nais?.length ?? 0) > 0 && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} mb={2}>
              <NAIMap nais={mission.nais as INAI[]} mapWidth="600px" />
            </Grid>
          </Grid>
        )}

        <Divider style={{ marginBottom: '16px' }} />
        <Typography variant="h3" mb={2}>
          {t('crew')}
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>
              {t('name')}
            </Typography>
            <Typography variant="body1">{crew?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" mb={1}>
              БпАК
            </Typography>
            {crew?.uav?.map((uav) => (
              <Stack key={uav.id} flexDirection="row" alignItems="center" gap={1}>
                <Typography variant="body1">{uav.name}</Typography>
                <UavTypeIcon type={mission.crew.uav[0].type} />
              </Stack>
            ))}
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>
              {t('location')}
            </Typography>
            <Typography variant="body1">{crew?.location?.name}</Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>
              {t('control_point_distance')}
            </Typography>
            <Typography variant="body1">{controlPointDistance / 1000} км</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" mb={1}>
              {t('crew_status')}
            </Typography>
            <Typography variant="body1">
              <StatusLabel currentStatus={crew?.status} id={crew?.id} type="crew" />
            </Typography>
          </Grid>
          {crew?.status === crewStatus.NOT_READY && (
            <Grid item xs={12}>
              <ReasonAlert reason={last(crew.reports)} color="red" title={t('reason_crew_not_ready')} />
            </Grid>
          )}
        </Grid>
        {mission.status === missionStatus.COMPLETED && mission.reports && mission.reports?.length > 0 && (
          <>
            <Divider style={{ marginBottom: '16px' }} />
            <Typography variant="h3" mb={2}>
              {t('report')}
            </Typography>
            {mission.reports?.map((report) => (
              <Box key={report.id} sx={{ mb: '16px' }}>
                <MissionReport report={report} />
              </Box>
            ))}
          </>
        )}
        {mission.target?.length > 0 && (
          <>
            <Divider style={{ marginBottom: '16px' }} />
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="h3" mb={2}>
                {t('targets')}
              </Typography>
              <Typography variant="h3" color={colors.grayscale.g1} mb={2}>
                {mission.target.length}
              </Typography>
            </Stack>
          </>
        )}
        {mission.target?.map((target) => (
          <TargetCardAccordion
            key={target.id}
            target={target}
            locationDistance={controlPointDistance}
            reconTarget={target.app6d_type === 'Орієнтир (точка інтересу)'}
          />
        ))}
      </DetailsCard>
      <EndMissionConfirmation
        title={t('finish_work')}
        subtitle={t('please_select_status')}
        mission={mission}
        open={endMissionConfirmation}
        onClose={() => setEndMissionConfirmation(false)}
        onSave={(status: string, report: string) => handleEndMissionSubmit(status, report)}
        status={endMissionStatus}
        setStatus={setEndMissionStatus}
      />
      <EndMissionConfirmation
        title={t('suspend_work')}
        subtitle={t('please_enter_suspend_reason')}
        mission={mission}
        open={suspendMissionConfirmation}
        onClose={() => setSuspendMissionConfirmation(false)}
        onSave={(status: string, report: string) => handleEndMissionSubmit(status, report)}
        status={endMissionStatus}
        setStatus={setEndMissionStatus}
      />
    </Stack>
  );
}

export default MissionDetailsSidebar;
