import React from 'react';
import { Box, Button, Dialog, Divider, Stack, TextareaAutosize, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from 'theme';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSave: (_reason: string) => void;
}

function CrewStatusConfirmation({ open, onClose, onSave }: IProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [reason, setReason] = React.useState<string>('');

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      maxWidth="lg"
      PaperProps={{
        sx: {
          background: colors.grayscale.b4,
        },
      }}
    >
      <Stack p={2} gap={2} height="100%">
        <Stack alignItems="flex-end" mb={2}>
          <CloseIcon onClick={onClose} />
        </Stack>
        <Typography variant="h3">{t('add_crew_not_ready_reason')}</Typography>
        <Typography color={colors.grayscale.g2}>{t('your_missions_will_be_suspended')}</Typography>
        <TextareaAutosize
          placeholder={t('add_report')}
          maxRows={10}
          onChange={(e) => setReason(e.target.value)}
          style={{
            backgroundColor: colors.grayscale.b4,
            border: '1px solid',
            borderRadius: '20px',
            padding: '10px',
            color: colors.grayscale.g2,
            minHeight: '150px',
          }}
        />
        <Box height="100%" />
        {isMobile && <Divider />}
        <Box display="flex" flexDirection="row-reverse" gap={1} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSave(reason);
              onClose();
            }}
          >
            {t('save')}
          </Button>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
}

export default CrewStatusConfirmation;
