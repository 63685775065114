import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box } from '@mui/material';
import { colors } from 'theme';
import { WarningIcon } from 'assets/icons';

function GbuAlert() {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        padding: '20px 24px',
        height: '136px',
        boxSizing: 'border-box',
        backgroundColor: colors.system.red,
        position: 'absolute',
        left: 0,
        right: 0,
      }}
    >
      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, color: colors.grayscale.b2 }}>{t('air_alarm')}</Typography>
        <Stack direction="row" alignItems="center" justifyContent="start" sx={{ width: '350px' }}>
          <Box
            sx={{
              height: '54px',
              width: '54px',
              borderRadius: '50%',
              backgroundColor: colors.base.red[900],
              boxSizing: 'border-box',
              padding: '10px 11px 16px',
              marginRight: '20px',
            }}
          >
            <WarningIcon />
          </Box>
          <Stack>
            <Typography color={colors.grayscale.b2} sx={{ fontWeight: 700 }} variant="h1">
              {t('threat_of_launches')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default GbuAlert;
