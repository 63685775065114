import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';

import { colors } from 'theme';
import { ITarget } from 'types/target';
import { useTranslation } from 'react-i18next';
import Chip from 'components/chip';
import TargetSign from './targetSign';
import TargetCounts from './targetCounts';
import StatusLabel from '../../../components/statusLabelDropdown';
import MissionTypeIcon from '../../../components/missionTypeIcon';
import UavTypeIcon from '../../../components/uavTypeIcon';
import { TARGET_STATUS } from '../../../constants/target';

interface Props {
  target: ITarget;
  selectTarget: (_target: ITarget) => void;
  selectedTargetId?: string;
  showStatus?: boolean;
  showCloseIcon?: boolean;
  onClose?: (_target: ITarget) => void;
  cursor?: string;
  showMissions?: boolean;
}

function TargetCard({
  target,
  selectTarget,
  selectedTargetId = '',
  showStatus = true,
  showCloseIcon = false,
  onClose = () => {},
  cursor = 'default',
  showMissions = false,
}: Props) {
  const photosCount = target.images.length;
  const completedMissions = target.missions?.filter((mission) => mission.finished);
  const activeMissions = target.missions?.filter((mission) => !mission.finished);
  const { t } = useTranslation();

  return (
    <Stack mb={1} key={target.id}>
      <Card onClick={() => selectTarget(target)} variant={selectedTargetId === target.id ? 'outlined' : 'elevation'}>
        <CardContent sx={{ padding: 2, ':last-child': { padding: 2 }, cursor }}>
          <Stack flexDirection="row" alignItems="center">
            <Stack flex="46px 0 0" mr="12px">
              <TargetSign width={46} height={46} sidc={target.app6d_sidc} />
            </Stack>
            <Stack alignContent="space-between" gap="7px" flex="1">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1}>
                  <Typography variant="body1">{target.name || target.uuid}</Typography>
                  {target.status === TARGET_STATUS.ARCHIVED && (
                    <Chip label={t('target_archived')} size="small" variant="outlined" />
                  )}
                </Stack>
                {showStatus && <StatusLabel type="target" currentStatus={target.status} id={target.id} />}
                {showCloseIcon && onClose && (
                  <IconButton
                    aria-label="close"
                    onClick={() => onClose(target)}
                    style={{ padding: '0', color: colors.grayscale.g2 }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">{target.app6d_type}</Typography>
                <TargetCounts
                  photos={photosCount}
                  comments={target.delta_comments?.length}
                  missions={completedMissions?.length}
                />
              </Stack>
            </Stack>
          </Stack>
          {showMissions && activeMissions?.length > 0 && (
            <>
              <Divider style={{ marginTop: '10px' }} />
              {activeMissions.map((mission) => (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  key={target.id + mission.id}
                >
                  <Stack flexDirection="row" alignItems="center" gap="5px">
                    <MissionTypeIcon type={mission.type} />
                    <Typography variant="body2">{mission.name}</Typography>
                    <StatusLabel currentStatus={mission.status} id={mission.id} type="mission" size="sm" />
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <UavTypeIcon type={mission.crew.uav[0].type} />
                    <Typography variant="body2" ml="5px">
                      {mission.crew?.name}
                    </Typography>
                    <Typography variant="body2">&nbsp;·&nbsp;{mission.crew?.uav[0].name}</Typography>
                    {mission.crew?.ammo.length > 0 && (
                      <Typography variant="body2">&nbsp;·&nbsp;{mission.crew?.ammo[0]?.name}</Typography>
                    )}
                  </Stack>
                </Stack>
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
}

export default TargetCard;
