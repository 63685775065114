import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme';
import { useAuth } from 'features/login/authProvider';
import { roles as rolesConstant } from 'features/admin/users/services/constants';

export default function RoleSwitcher() {
  const { t } = useTranslation();
  const { user, switchRole } = useAuth();

  if (!user?.role) return null;

  const sortRoles = (roles: string[]): string[] => {
    const rolePriorityMap = new Map(rolesConstant.map((name, idx) => [name, idx]));
    return [...roles].sort((a, b) => (rolePriorityMap.get(a) ?? Infinity) - (rolePriorityMap.get(b) ?? Infinity));
  };

  return (
    <div>
      <MenuItem disableRipple disabled sx={{ height: '46px' }}>
        <Box>
          <Typography sx={{ color: colors.text.tertiary }} variant="body2">
            {t('products')}
          </Typography>
        </Box>
      </MenuItem>
      {sortRoles(user.role).map((role) => {
        const isCurrent = user?.currentRole === role;
        return (
          <MenuItem
            key={role}
            onClick={() => switchRole(role)}
            disableRipple
            sx={{ height: '46px', marginBottom: '5px', padding: '14px' }}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="body1" color={colors.grayscale.w0}>
                {t(role)}
              </Typography>
              {isCurrent && <Check fontSize="small" sx={{ color: colors.system.g1 }} />}
            </Box>
          </MenuItem>
        );
      })}
    </div>
  );
}
