import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import api, { apiURLs } from 'services/api';

interface ISubmitNAI {
  name: string;
  coordinates: string[];
}

export const useSubmitNAI = (zoneId: string, onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['submit-nai'],
    mutationFn: async (nai: ISubmitNAI) => {
      await api.post(apiURLs.nais.create, {
        zoneId,
        name: nai.name,
        coordinates: nai.coordinates,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
      toast.success(t('nai_added'));
      if (onSuccessCallback) onSuccessCallback();
    },
    onError: (err: any) => {
      toast.error(err.response?.data?.message || t('failed_to_add_nai'));
    },
  });
};

export default useSubmitNAI;
