import React from 'react';

function WarningIcon() {
  return (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M17.299 1.49993L31.588 26.2499C31.7197 26.478 31.789 26.7366 31.789 26.9999C31.789 27.2632 31.7197 27.5219 31.588 27.7499C31.4564 27.9779 31.267 28.1673 31.039 28.299C30.811 28.4306 30.5523 28.4999 30.289 28.4999H1.71101C1.4477 28.4999 1.18904 28.4306 0.96102 28.299C0.732996 28.1673 0.543645 27.9779 0.411997 27.7499C0.280349 27.5219 0.211043 27.2632 0.211044 26.9999C0.211046 26.7366 0.280355 26.478 0.412006 26.2499L14.701 1.49993C14.8327 1.27192 15.022 1.08258 15.25 0.950943C15.4781 0.819302 15.7367 0.75 16 0.75C16.2633 0.75 16.522 0.819302 16.75 0.950943C16.978 1.08258 17.1673 1.27192 17.299 1.49993ZM4.30901 25.4999H27.691L16 5.24993L4.30901 25.4999ZM14.5 20.9999H17.5V23.9999H14.5V20.9999ZM14.5 10.4999H17.5V17.9999H14.5V10.4999Z"
        fill="#FF8080"
      />
    </svg>
  );
}

export default WarningIcon;
