import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import api, { apiURLs } from 'services/api';

interface IUpdateNAI {
  id: string;
  name: string;
  coordinates: string[];
}

export const useUpdateNAI = (onSuccessCallback?: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['update-nai'],
    mutationFn: async ({ id, name, coordinates }: IUpdateNAI) => {
      await api.patch(apiURLs.nais.update(id), {
        name,
        coordinates,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
      toast.success(t('nai_updated'));
      if (onSuccessCallback) onSuccessCallback();
    },
    onError: (err: any) => {
      toast.error(err.response?.data?.message || t('failed_to_update_nai'));
    },
  });
};

export default useUpdateNAI;
