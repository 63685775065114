import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function EW({ width = 23, height = 23 }: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.2"
      baseProfile="tiny"
      viewBox="26 26 148 148"
    >
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#ffffff"
        fill="none"
      />
      <path
        d="M 100,28 L172,100 100,172 28,100 100,28 Z Z"
        strokeWidth="44"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
        fill="none"
      />
      <path
        d="M 100,28 L172,100 100,172 28,100 100,28 Z"
        strokeWidth="4"
        stroke="black"
        fill="rgb(255,128,128)"
        fillOpacity="1"
      />
      <text
        x="100"
        y="115"
        textAnchor="middle"
        fontSize="42"
        fontFamily="Arial"
        fontWeight="bold"
        strokeWidth="4"
        stroke="none"
        fill="black"
      >
        EW
      </text>
      <path
        // eslint-disable-next-line max-len
        d="M67,60 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 13,10   M52,75 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 10,-10 c10,0 0,10 10,10 c10,0 0,-10 7,-10"
        strokeWidth="4"
        stroke="black"
        fill="none"
      />
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="4"
        stroke="none"
        fill="#000000"
      />
    </svg>
  );
}

export default EW;
