import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Hidden, Stack, Typography } from '@mui/material';
import Search from 'components/search';

interface Props {
  title?: string;
  onCreate?: () => void;
  createLabel?: string;
  hideSearch?: boolean;
}

export default function PageHeader({ title = '', onCreate, createLabel = 'Create', hideSearch = false }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search');

  const onSearch = (text: string) => {
    searchParams.set('search', text || '');
    setSearchParams(searchParams);
  };

  return (
    <Stack>
      <Hidden mdDown>
        <Stack direction="row" gap={5} alignItems="center" justifyContent="space-between" p={2} pl={0} pr={0}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h1">{title}</Typography>
            {!hideSearch && <Search onSearch={onSearch} defaultValue={searchValue} />}
          </Stack>
          {onCreate && (
            <Button
              sx={{
                padding: '0 18px',
                height: '45px',
                fontSize: '14px',
                fontWeight: 700,
                whiteSpace: 'nowrap',
              }}
              onClick={onCreate}
              variant="contained"
              color="primary"
            >
              {createLabel}
            </Button>
          )}
        </Stack>
      </Hidden>
      <Hidden mdUp>
        <Stack direction="column" gap={5} alignItems="center" justifyContent="space-between" p={2} pl={0} pr={0}>
          <Typography variant="h1">{title}</Typography>
          <Stack direction="row" gap={5} alignItems="center" justifyContent="space-between">
            {!hideSearch && <Search onSearch={onSearch} defaultValue={searchValue} />}
            {onCreate && (
              <Button onClick={onCreate} variant="contained" color="primary" size="large">
                {createLabel}
              </Button>
            )}
          </Stack>
        </Stack>
      </Hidden>
    </Stack>
  );
}
