import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function UndergroundCover({ width = 23, height = 15 }: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0.33 22 13.53"
    >
      <g transform="translate(11,7) scale(0.01375,-0.01375)">
        <path d="M-512 -499v906h1026v-906h-1026zM800 410h-1600v75h1600v-75z" fill="#FF0000" />
      </g>
    </svg>
  );
}

export default UndergroundCover;
