import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import api, { apiURLs } from 'services/api';
import { IZone } from 'types/zone';

export const useUpdateZone = (onSuccessCallback?: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-zone'],
    mutationFn: async ({ zoneId, data }: { zoneId: string; data: IZone }) => {
      await api.patch(apiURLs.zones.updateZone(zoneId), data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['zone-list'] });
      toast.success(t('zone_updated'));
      if (onSuccessCallback) onSuccessCallback();
    },
    onError: (err: any) => {
      toast.error(err.response?.data?.error || t('failed_to_update_zone'));
    },
  });
};

export default useUpdateZone;
