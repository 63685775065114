import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';

import FakeModal from 'components/fakeModal';
import { urls } from 'router';

import TargetSelectUploadFile from '../components/targetSelectUploadFile';
import TargetUploadSuccess from '../components/targetUploadSuccess';
import TargetUploadError from '../components/TargetUploadError';

import './targetImport.css';
import deltaLogo from '../images/delta_logo.png';

function TargetsImportPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fileUploadedStatus, setFileUploadedStatus] = useState('upload_select');
  const [targets, setTargets] = useState([]);

  return (
    <Container maxWidth="xl">
      <FakeModal
        title={t('import_targets_from_delta')}
        cancel={() => navigate(urls.targets.landing)}
        hideControls
        logo={deltaLogo}
      >
        <Container maxWidth="lg">
          {fileUploadedStatus === 'upload_select' && (
            <TargetSelectUploadFile setFileUploadedStatus={setFileUploadedStatus} setTargets={setTargets} />
          )}
          {fileUploadedStatus === 'upload_in-progress' && <Box className="drop-file-container">{t('uploading')}</Box>}
          {fileUploadedStatus === 'upload_success' && (
            <TargetUploadSuccess targets={targets} setFileUploadedStatus={setFileUploadedStatus} />
          )}
          {fileUploadedStatus === 'upload_error' && <TargetUploadError setFileUploadedStatus={setFileUploadedStatus} />}
        </Container>
      </FakeModal>
    </Container>
  );
}

export default TargetsImportPage;
