import { createTheme } from '@mui/material/styles';

export const colors = {
  grayscale: {
    b1: '#000000',
    b2: '#171717',
    b3: '#202020',
    b4: '#272727',
    b5: '#454545',
    b6: '#363636',
    g1: '#5F5F5F',
    g2: '#AFAFAF',
    w1: '#CCCCCC',
    w0: '#FFFFFF',
  },
  system: {
    g1: '#7BB350',
    yellow0: '#ffcb67',
    yellow1: '#F5AB1B',
    yellow2: '#4A400C',
    red: '#E25850',
    notification: '#FF8080',
  },
  brand: {
    b1: '#121E17',
    b2: '#354B2B',
    b3: '#2F5623',
    b3_1: '#417332',
    b3_2: '#1A322C',
    b4: '#6D834A',
  },
  mission: {
    recon: {
      500: '#A180FF',
      900: '#372362',
    },
    strike: {
      500: '#FF8080',
      900: '#572A27',
    },
    mine: {
      500: '#FFD280',
      900: '#5F4A1B',
    },
  },
  base: {
    red: {
      900: '#300704',
    },
    gray: {
      700: '#353537',
    },
  },
  text: {
    tertiary: '#757575',
  },
};

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 660,
      lg: 980,
      xl: 1388,
    },
  },
  palette: {
    mode: 'dark',
    background: {
      paper: colors.grayscale.b3,
      default: colors.grayscale.b3,
    },
    primary: {
      main: colors.brand.b3,
    },
    secondary: {
      main: colors.grayscale.g2,
    },
    text: {
      primary: colors.grayscale.w1,
      secondary: colors.grayscale.g2,
      disabled: colors.grayscale.g2,
    },
    error: {
      main: colors.system.red,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'e-Ukraine',
    ],
    h1: {
      fontSize: '26px',
      fontWeight: 500,
      color: colors.grayscale.w1,
    },
    h2: {
      fontSize: '22px',
      fontWeight: 400,
      color: colors.grayscale.w1,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 400,
      color: colors.grayscale.w1,
    },
    body1: {
      fontSize: '14px',
      color: colors.grayscale.w1,
    },
    body2: {
      fontSize: '12px',
      color: colors.grayscale.g2,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: `2px solid ${colors.grayscale.b5}`,
          padding: '7px 22px',
          borderRadius: '24px',
          textTransform: 'none',
          '&.MuiButton-containedPrimary': {
            borderWidth: '2px',
            borderColor: colors.brand.b3,
          },
          '&.MuiButton-containedPrimary:hover': {
            borderWidth: '2px',
            color: colors.grayscale.w1,
            backgroundColor: colors.brand.b3_1,
            borderColor: colors.brand.b3_1,
          },
          '&.MuiButton-containedPrimary:active': {
            borderWidth: '2px',
            borderColor: colors.brand.b3,
            backgroundColor: colors.brand.b3,
          },
          '&.MuiButton-outlinedSecondary': {
            borderWidth: '2px',
            borderColor: colors.grayscale.g1,
            color: colors.grayscale.w1,
          },
          '&.MuiButton-outlinedSecondary:hover': {
            borderWidth: '2px',
            backgroundColor: colors.grayscale.b2,
            borderColor: colors.grayscale.g1,
            color: colors.grayscale.w1,
          },
          '&.MuiButton-outlinedSecondary:active': {
            borderWidth: '2px',
            borderColor: colors.grayscale.g1,
            color: colors.grayscale.g2,
          },
          '&.MuiButton-outlinedPrimary': {
            borderWidth: '2px',
            borderColor: colors.brand.b3,
            color: colors.grayscale.w1,
          },
          '&.MuiButton-outlinedPrimary:hover': {
            borderWidth: '2px',
            borderColor: colors.grayscale.w1,
            color: colors.grayscale.w1,
            backgroundColor: colors.brand.b1,
          },
          '&.MuiButton-outlinedPrimary:active': {
            borderSize: '2px',
            borderColor: colors.grayscale.g2,
            color: colors.grayscale.g2,
            backgroundColor: colors.brand.b1,
          },
          '&.MuiButton-outlinedPrimary:disabled': {
            borderWidth: '1px',
            borderColor: colors.grayscale.g1,
            color: colors.grayscale.g1,
          },
        },
      },
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            borderRadius: '24px',
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // for error messages in TextField
          // without this TextField is jumping when error message appears
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          '.MuiTab-root': {
            minHeight: 'auto',
            minWidth: 'auto',
            padding: '7.5px 12px',
          },
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase transformation
          borderRadius: '100px',
          marginRight: '35px',
          transition: '0.3s ease-out',
          fontWeight: 400,
          '&.Mui-selected': {
            backgroundColor: colors.base.gray[700],
            color: colors.grayscale.w0,
            '&:hover': {
              backgroundColor: colors.brand.b3_1,
              color: colors.grayscale.w0,
            },
          },
          '&:hover': {
            color: colors.grayscale.w0,
            backgroundColor: colors.brand.b3_1,
          },
          '&:active': {
            transition: 'none',
            color: colors.grayscale.g2,
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          minHeight: '',
          backgroundColor: colors.grayscale.b2,
          backgroundImage: 'none',
          borderRadius: 24,
          '&:before, &:after': {
            content: 'none',
          },
          '&.Mui-expanded': {
            margin: '0 0 10px 0',
          },
          '.MuiAccordionSummary-content': {
            margin: '23px 0',
            padding: '0',
          },
          boxShadow: 'none',
        },
      },
      defaultProps: {
        square: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: 'none',
          border: `1px solid ${colors.grayscale.b4}`,
          background: colors.grayscale.b3,
          '&.MuiPaper-outlined': {
            transition: '0.3s ease-out',
            backgroundColor: colors.brand.b1,
            border: `1px solid ${colors.brand.b3}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: colors.grayscale.g2,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderWidth: '2px',
          padding: '4px 4px',
          'input:-webkit-autofill': {
            WebkitTransitionDelay: '9999999s',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            margin: '8px 0',
            borderRadius: '16px',
            backgroundColor: colors.grayscale.b1,
            boxShadow: '0 10px 20px RGBA(0, 0, 0, .25)',
          },
          '.MuiAutocomplete-listbox .MuiAutocomplete-option': {
            margin: '6px',
            padding: '14px',
          },
          '.MuiAutocomplete-option.Mui-focused': {
            borderRadius: '24px',
            backgroundColor: colors.brand.b1,
          },
          '.MuiAutocomplete-option[aria-selected="true"]': {
            borderRadius: '24px',
            backgroundColor: colors.brand.b1,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 1000,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            color: colors.grayscale.w1,
            textDecoration: 'underline',
            textDecorationColor: colors.grayscale.w1,
          },
        },
      },
    },
  },
});
