import React from 'react';
import { Dialog, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Transition } from 'components/header/mobileMenu';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { toast } from 'react-toastify';
import { IMissionAudit } from 'types/mission';
import './missionHistoryDialog.css';
import { StatusLabel } from 'components/statusLabelDropdown/statusLabel';
import { formatDate } from 'services/dayjs';

interface IProps {
  manageVisibility: (_isVisible: boolean) => void;
  isVisible: boolean;
  missionId: string;
}

function MissionHistoryDialog({ manageVisibility, isVisible, missionId }: IProps) {
  const { t } = useTranslation();
  const { data: missionAudit } = useQuery({
    queryKey: [`mission-audit-${missionId}`],
    queryFn: (): Promise<IMissionAudit[]> =>
      api
        .get(apiURLs.missions.audit(missionId))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
    enabled: !!missionId,
  });

  return (
    <Dialog
      open={isVisible}
      TransitionComponent={Transition}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '0',
          padding: '33px',
          background: '#202020',
        },
        width: 500,
        left: 'auto',
        paddingTop: '32px',
      }}
      onClose={manageVisibility}
    >
      <Stack direction="row">
        <CloseIcon onClick={() => manageVisibility(false)} sx={{ cursor: 'pointer' }} />
      </Stack>

      <h3>{t('history_of_changes')}</h3>
      {missionAudit
        ?.sort((a, b) => {
          const dateA = a.updated_at?.current ? new Date(a.updated_at.current).getTime() : 0;
          const dateB = b.updated_at?.current ? new Date(b.updated_at.current).getTime() : 0;

          return dateB - dateA;
        })
        .map(({ id, status, updated_at: updatedAt, updated_by: updatedBy, due_date: dueDate }) => (
          <div className="historyItem" key={id}>
            {updatedBy?.current && <div className="historyItemUser">by {updatedBy.current.username}</div>}
            <div className="historyItemDate">{formatDate(updatedAt?.current)}</div>
            {status?.current && (
              <>
                <h5>Змінено статус</h5>
                <StatusLabel className={`${status.previous}`}>{t(`status_mission_${status.previous}`)}</StatusLabel>
                <span className="transitionArrow" />
                <StatusLabel className={`${status.current}`}>{t(`status_mission_${status.current}`)}</StatusLabel>
              </>
            )}
            {dueDate?.current && (
              <>
                <h5>Дата виконання</h5>
                <span className="historyItemTextPrev">{`${formatDate(dueDate.previous)}`}</span>
                <span className="transitionArrow" />
                <span className="historyItemText">{`${formatDate(dueDate.current)}`}</span>
              </>
            )}
          </div>
        ))}
    </Dialog>
  );
}

export default MissionHistoryDialog;
