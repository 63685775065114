import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Skeleton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { urls } from 'router';
import MissionTypeIcon from 'components/missionTypeIcon';
import KHTable from 'components/khTable';
import { IUAV } from 'types/uav';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  uavs: IUAV[];
  isLoading?: boolean;
  deleteItem: (_id: string) => Promise<void>;
}

function UAVTable({ uavs, isLoading, deleteItem }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const UAV_TYPES: any = {
    recon: {
      label: 'Розвідка',
      icon: 'recon',
    },
    strike: {
      label: 'Ударні',
      icon: 'strike',
    },
  };

  return (
    <KHTable>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2">#</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('name')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('type')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('details')}</Typography>
          </TableCell>
          <TableCell width="25%">
            <Typography variant="body2">ID</Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading &&
          Array.from(Array(5)).map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        {!isLoading &&
          uavs?.map((uav, i) => (
            <TableRow key={uav.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
              <TableCell onClick={() => navigate(urls.admin.uav.edit(uav.id))}>
                <Typography variant="body1">{i + 1}</Typography>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.uav.edit(uav.id))}>
                <Typography variant="body1">{uav.name}</Typography>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.uav.edit(uav.id))}>
                <Box display="flex" gap="12px" alignItems="center">
                  <Typography variant="body1">{UAV_TYPES[uav.type].label}</Typography>
                  <MissionTypeIcon type={UAV_TYPES[uav.type].icon} />
                </Box>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.uav.edit(uav.id))}>
                <Typography variant="body1">{uav.description}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" onClick={() => navigate(urls.admin.uav.edit(uav.id))}>
                <Typography variant="body1">{uav.id}</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="secondary"
                  className="action-icon"
                  onClick={() => navigate(urls.admin.uav.edit(uav.id))}
                >
                  <EditIcon />
                </IconButton>
                <IconButton color="secondary" className="action-icon" onClick={() => uav.id && deleteItem(uav.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </KHTable>
  );
}

UAVTable.defaultProps = {
  isLoading: false,
};

export default UAVTable;
