import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import api, { apiURLs } from 'services/api';
import { IZone } from 'types/zone';

interface IUseZoneDetails {
  id: string;
}

export const useZoneDetails = ({ id }: IUseZoneDetails) => {
  const { data, refetch } = useQuery<IZone, Error>({
    queryKey: ['zone-details'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.zones.details(id));
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch mission details');
        throw err;
      }
    },
  });

  return { data, refetch };
};

export default useZoneDetails;
