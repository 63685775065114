import React from 'react';
import { Paper, styled, Typography } from '@mui/material';
import { colors } from 'theme';

interface CardProps {
  counter: number;
  title: string;
  onClick: Function;
}

const Count = styled(Typography)`
  color: #cccccc;
  font-size: 54px;
`;

const CardBox = styled(Paper)`
  height: 225px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid ${colors.grayscale.b4};
  padding: 32px;
  :hover {
    border: 1px solid ${colors.grayscale.b5};
    cursor: pointer;
  }
`;

export default function OverviewCard({ counter, title, onClick }: CardProps) {
  return (
    <CardBox onClick={() => onClick()}>
      <Count color={colors.grayscale.w1}>{counter}</Count>
      <Typography variant="body1" color={colors.grayscale.g2}>
        {title}
      </Typography>
    </CardBox>
  );
}
