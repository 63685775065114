import React from 'react';

interface IProps {
  width?: number;
  height?: number;
}

function Howitzer({ width = 23, height = 23 }: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.2"
      baseProfile="tiny"
      viewBox="26 26 148 148"
    >
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#ffffff"
        fill="none"
      />
      <path
        d="M100,28 L172,100 100,172 28,100 100,28 Z Z"
        strokeWidth="44"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="none"
        fill="none"
      />
      <path
        d="M100,28 L172,100 100,172 28,100 100,28 Z"
        strokeWidth="4"
        stroke="black"
        fill="rgb(255,128,128)"
        fillOpacity="1"
      />
      <circle cx="100" cy="130" r="10" strokeWidth="4" stroke="black" fill="none" />
      <path d="m 115,80 0,40 m -30,-40 0,40 m 15,-60 0,60" strokeWidth="4" stroke="black" fill="none" />
      <text
        x="192"
        y="70"
        textAnchor="start"
        fontSize="60"
        fontFamily="Arial"
        strokeWidth="4"
        stroke="none"
        fill="#000000"
      />
    </svg>
  );
}

export default Howitzer;
