import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import FakeModal from 'components/fakeModal';
import { urls } from 'router';
import { ICreateZoneDto, IZone, ZoneFormModes } from 'types/zone';
import defaultZone from 'defaults/zone';
import { getValidationErrors } from 'services/helpers';
import ZoneForm from '../components/zoneForm';

import { useZoneDetails, useUpdateZone } from '../services/hooks';

const schema = yup
  .object({
    name: yup.string().required('name_required').min(2, 'name_too_short'),
    zoneId: yup.string(),
  })
  .required();

function EditZone() {
  const { id: zoneId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [zone, setZone] = useState<ICreateZoneDto | IZone | undefined>(defaultZone);
  const [errors, setErrors] = useState({});

  const { data: zoneDetails } = useZoneDetails({ id: zoneId });
  useEffect(() => setZone(zoneDetails), [zoneDetails]);

  const updateZone = useUpdateZone(() => navigate(urls.zones.landing));

  function validateAndSubmit() {
    schema
      .validate(zone, { abortEarly: false })
      .then(() => {
        setErrors({});
        if (zone) {
          updateZone.mutate({ zoneId, data: zone as IZone });
        }
      })
      .catch((err: ValidationError) => {
        setErrors(getValidationErrors(err));
      });
  }
  return (
    <Container maxWidth="xl">
      <FakeModal title={t('edit_zone')} cancel={() => window.history.go(-1)} onSubmit={() => validateAndSubmit()}>
        {/* @ts-ignore */}
        <ZoneForm zone={zone} setZone={setZone} errors={errors} mode={ZoneFormModes.EDIT} />
      </FakeModal>
    </Container>
  );
}

export default EditZone;
