import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IGetZoneListResponse } from '../../../types/zone';
import api, { apiURLs } from '../../../services/api';
import { BaseFilters } from '../../../services/model';
import Requests from '../components/requests';
import ZoneTile from '../components/zoneTile';
import GbuThreat from '../components/gbuThreat';
import { ICreateEventLogPayload, IEventLogEntry, IPlaceEventLogEntry } from '../../../types/eventlog';
import { colors } from '../../../theme';

function LoadingSkeleton() {
  return (
    <>
      <Grid item xs={12} md={4}>
        <Skeleton />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton />
      </Grid>
    </>
  );
}

function AirDefenceAlerts() {
  const filters = { page: 1, limit: 100 } as BaseFilters;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: zoneList, isFetching: isFetchingZoneList } = useQuery({
    queryKey: ['zone-list', filters],
    queryFn: (): Promise<IGetZoneListResponse> =>
      api
        .get(apiURLs.zones.listAAD(null, 1, 100))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  const { data: threats } = useQuery({
    queryKey: ['threats'],
    queryFn: (): Promise<IEventLogEntry[]> =>
      api
        .get(apiURLs.eventlog.threat)
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
    refetchInterval: 30000,
  });

  const { data: requests } = useQuery({
    queryKey: ['requests'],
    queryFn: (): Promise<IPlaceEventLogEntry[]> =>
      api
        .get(apiURLs.eventlog.placeRequests)
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
    refetchInterval: 30000,
  });

  const { mutate: createEventLogEntry } = useMutation({
    mutationKey: ['create_threat'],
    mutationFn: (m: ICreateEventLogPayload) =>
      api
        .post(apiURLs.eventlog.create, m)
        .then(({ data }) => data)
        .catch((err) => toast.error(err.response.data?.error)),
    onSuccess: (log: ICreateEventLogPayload, _variables: any) =>
      queryClient.setQueryData(['threats'], (logs: IEventLogEntry[]) => {
        if (log.data.type === 'threat_stop') {
          if (log.data.entityType === 'gbu') {
            return logs.filter((l) => l.entity_type !== 'gbu');
          }
          return logs.filter((l) => l.entity_id !== log.data.entityId);
        }
        if (log.data.type === 'threat') {
          if (log.data.entityType === 'gbu') {
            return [
              ...logs,
              {
                entity_type: 'gbu',
                event_type: 'threat',
                created_at: log.created_at,
              },
            ];
          }
          if (log.data.entityType === 'zone' || log.data.entityType === 'place') {
            return [
              ...logs,
              {
                entity_id: log.data.entityId,
                entity_type: log.data.entityType,
                event_type: 'threat',
                created_at: log.created_at,
              },
            ];
          }
        }
        return logs;
      }),
  });

  const createGbuThreat = (eventType: string) => {
    createEventLogEntry({ data: { type: eventType, entityType: 'gbu' } });
  };

  const createZoneThreat = (zoneId: string, eventType: string) => {
    createEventLogEntry({ data: { type: eventType, entityType: 'zone', entityId: zoneId } });
  };

  const createPlaceThreat = (entityId: string, eventType: string) => {
    createEventLogEntry({ data: { type: eventType, entityType: 'place', entityId } });
  };

  const gbuThreat = threats?.some((threat) => threat.entity_type === 'gbu');

  return (
    <Grid container p={4} spacing={2}>
      <Grid item xs={12} md={8}>
        <GbuThreat danger={!!gbuThreat} onChange={(checked) => createGbuThreat(checked ? 'threat' : 'threat_stop')} />
        <Grid container spacing="20px">
          <Grid item xs={12} md={12}>
            <Typography variant="h1" mt={4} color={colors.grayscale.w0}>
              {t('zones')}
            </Typography>
          </Grid>
          {isFetchingZoneList ? (
            <LoadingSkeleton />
          ) : (
            zoneList?.results?.map((zone) => (
              <Grid item xs={12} md={4}>
                <ZoneTile
                  name={zone.name}
                  danger={!!threats?.some((threat: IEventLogEntry) => threat.entity_id === zone.id)}
                  onChange={(checked) => createZoneThreat(zone.id!, checked ? 'threat' : 'threat_stop')}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Requests
          requests={requests || []}
          threats={threats || []}
          onChange={(entityId: string, danger: boolean) =>
            createPlaceThreat(entityId, danger ? 'threat' : 'threat_stop')
          }
        />
      </Grid>
    </Grid>
  );
}

export default AirDefenceAlerts;
