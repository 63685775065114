import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { CameraIcon } from 'assets/icons';
import { colors } from 'theme';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import { IImage } from 'types/target';

interface IProps {
  images: IImage[];
}

function TargetImagesGallery({ images }: IProps) {
  const { t } = useTranslation();

  return images?.length > 0 ? (
    <Stack mb={2}>
      <Stack flexDirection="row" gap="8px" alignItems="center" mb={2}>
        <CameraIcon fill={colors.grayscale.w1} width={24} height={24} />
        <Typography variant="body1">{t('photo')}</Typography>
        <Typography variant="body1">{images.length}</Typography>
      </Stack>
      <Stack>
        <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]} animateThumb>
          {images.map((image) => (
            <a key={image.id} href={image.imageUrl} className="image-thumbnail">
              <img alt="img1" src={image.imageUrl} />
            </a>
          ))}
        </LightGallery>
      </Stack>
    </Stack>
  ) : (
    <span />
  );
}

export default TargetImagesGallery;
