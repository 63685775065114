import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

function MineIcon({ width = 20, height = 14, fill = '#5F5F5F' }: IProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99 5.96809C8.85795 5.96293 7.79054 5.80534 6.98285 5.52384C5.81521 5.11671 5.57272 4.59131 5.57339 4.22165L5.57963 3.15244C5.10129 3.25436 4.6542 3.37285 4.24325 3.50985C2.83265 3.97816 2.19743 4.55141 2.19667 4.90785C2.19623 5.26584 2.82311 5.84362 4.23099 6.3249C5.76233 6.84758 7.81106 7.14353 10.0033 7.15324C12.1944 7.16484 14.2463 6.88876 15.7829 6.37926C17.1935 5.91095 17.8273 5.33964 17.8293 4.9813C17.8297 4.62331 17.2013 4.04587 15.795 3.56425C15.3757 3.42117 14.9177 3.2951 14.4284 3.18725L14.4221 4.2642C14.4204 4.63248 14.1725 5.15537 13.0009 5.55118C12.7647 5.63106 12.507 5.70028 12.2318 5.758C11.5628 5.89834 10.7922 5.97221 9.99 5.96809ZM5.69254 1.81791C5.85269 1.52743 6.22366 1.21027 7.0083 0.949569C7.81798 0.681572 8.88651 0.536438 10.0177 0.541768C11.1498 0.546925 12.2167 0.702228 13.0233 0.978385C13.7982 1.24397 14.1664 1.56153 14.3261 1.85158C15.0072 1.98571 15.6413 2.1517 16.212 2.34711C18.6161 3.17108 19.1174 4.23821 19.1137 4.98764L19.0897 9.95279C19.0861 10.7104 18.5762 11.7861 16.166 12.6003C15.68 12.7646 15.15 12.907 14.5838 13.0258C13.2076 13.3145 11.6222 13.4664 9.97204 13.458C7.64322 13.4473 5.44739 13.1232 3.78585 12.5441L3.78624 12.5439C1.38422 11.7064 0.884976 10.6257 0.886355 9.86525L0.910404 4.90011C0.914093 4.15067 1.4254 3.08949 3.8382 2.28783C4.40076 2.10163 5.02405 1.94426 5.69254 1.81791ZM16.5326 7.478L16.4582 11.1073C17.4296 10.6737 17.8042 10.229 17.8056 9.94626L17.8202 6.86148C17.4809 7.07549 17.0581 7.28241 16.5326 7.478Z"
        fill={fill}
      />
    </svg>
  );
}

export default MineIcon;
