import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton, Stack, styled, Typography } from '@mui/material';
import { colors } from 'theme';

import { TMissionStatus } from 'types/mission';

const ToggleButtonStyled = styled(ToggleButton)`
  background-color: transparent;
  color: ${colors.grayscale.w0};
  height: 36px;
  width: 121px;
  :hover {
    background-color: ${colors.brand.b2};
    border: transparent;
  }
  &.Mui-selected {
    border-radius: 24px;
    background-color: ${colors.brand.b3_2};
    border: transparent;
    &:hover {
      background-color: ${colors.brand.b2};
    }
  }
  text-transform: none;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButtonGroup-firstButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
  .MuiToggleButtonGroup-middleButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
  .MuiToggleButtonGroup-lastButton {
    border: 2px solid ${colors.grayscale.b5};
    border-radius: 24px;
  }
`;

interface IProps {
  selectedStatus: TMissionStatus | undefined;
  setSelectedStatus: (_type: TMissionStatus) => void;
}

const statuses = ['completed', 'not_completed'];

function MissionStatusToggleButton({ selectedStatus, setSelectedStatus }: IProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" sx={{ padding: '6px 0 0 0' }}>
      <StyledToggleButtonGroup value={selectedStatus} onChange={(_, value) => setSelectedStatus(value)} exclusive>
        {statuses.map((status) => (
          <ToggleButtonStyled sx={{ marginRight: '7px' }} key={status} value={status}>
            <Stack flexDirection="row" alignItems="center" gap="1px">
              <Typography> {t(`status_mission_${status}`)} </Typography>
            </Stack>
          </ToggleButtonStyled>
        ))}
      </StyledToggleButtonGroup>
    </Stack>
  );
}

export default MissionStatusToggleButton;
