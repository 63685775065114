import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Box, Button, Stack } from '@mui/material';

import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import PageHeader from 'components/pageHeader';
import { Ammunition, AmmunitionRes } from '../services/model';
import AmmunitionTable from '../components/ammunitionTable';
import { BaseFilters } from '../../../../services/model';

function AdminAmmunitionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get('search');
  const filters = { page: 1, limit: 10, search } as BaseFilters;
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState<Ammunition[]>([]);

  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['ammunition-list', filters],
    queryFn: (): Promise<AmmunitionRes> =>
      api
        .get(apiURLs.admin.ammunition.list(filters))
        .then((res) => res.data)
        .catch((err) => toast.error(err.response.data?.message)),
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => api.delete(apiURLs.admin.ammunition.delete(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ammunition-list', filters] });
    },
  });

  const deleteItem = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id);
      toast.success(t('ammunitionDeleted'));
    } catch (error: any) {
      toast.error(error.response.data?.error);
    }
  };

  useEffect(() => {
    if (data?.results && currentPage === 1) {
      setResults(data.results);
    }
  }, [data, currentPage]);

  const loadMore = async () => {
    setCurrentPage(currentPage + 1);
    try {
      filters.page = currentPage + 1;
      const previousData = results;
      const newResults = await refetch();
      setResults([...(previousData || []), ...(newResults.data?.results || [])]);
    } catch (err) {
      toast.error((err as Error).message);
    }
  };

  return (
    <>
      <PageHeader createLabel={t('add')} title={t('ammo')} onCreate={() => navigate(urls.admin.ammunition.create)} />
      <Box sx={{ overflowX: 'auto' }}>
        <AmmunitionTable ammunitionList={results} isLoading={isLoading} deleteItem={deleteItem} />
      </Box>
      <Stack direction="row" justifyContent="center" className="footer-container">
        <Button
          variant="outlined"
          color="primary"
          className="footer-button"
          onClick={loadMore}
          disabled={isFetching || currentPage >= (data?.pageCount || 0)}
        >
          {t('loadMore')}
        </Button>
      </Stack>
    </>
  );
}

export default AdminAmmunitionPage;
