import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconButton, Skeleton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { urls } from 'router';
import KHTable from 'components/khTable';
import { Ammunition } from '../services/model';

interface Props {
  ammunitionList: Ammunition[];
  isLoading?: boolean;
  deleteItem: (_id: string) => Promise<void>;
}

export default function AmmunitionTable({ ammunitionList, isLoading = false, deleteItem }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <KHTable>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2">#</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('name')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('quantity')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('details')}</Typography>
          </TableCell>
          <TableCell width="30%">
            <Typography variant="body2">ID</Typography>
          </TableCell>
          <TableCell width="60px" />
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading &&
          Array.from(Array(5)).map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        {!isLoading &&
          ammunitionList?.map((ammunition, i) => (
            <TableRow key={ammunition.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
              <TableCell onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}>
                <Typography variant="body1">{i + 1}</Typography>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}>
                <Typography variant="body1">{ammunition.name}</Typography>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}>
                <Typography variant="body1">{ammunition.quantity || 0}</Typography>
              </TableCell>
              <TableCell onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}>
                <Typography variant="body1">{ammunition.description}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}>
                <Typography variant="body1">{ammunition.id}</Typography>
              </TableCell>
              <TableCell align="right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  color="secondary"
                  className="action-icon"
                  onClick={() => ammunition.id && deleteItem(ammunition.id)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  className="action-icon"
                  onClick={() => navigate(urls.admin.ammunition.edit(ammunition.id))}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </KHTable>
  );
}
