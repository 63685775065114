import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import { urls } from 'router';
import { ICreateZoneDto, IZone, INAI, IGetRelatedMissions } from 'types/zone';
import defaultZone from 'defaults/zone';

import GroupBy from 'components/groupBy';
import NAIMap from '../components/naiMap';
import NAICard from '../components/naiCard';
import RelatedMissions from '../components/relatedMissions';

import { useZoneDetails, useRelatedMissions } from '../services/hooks';

const groupByItems = ['zone-details', 'related-missions'];

const StyledButton = styled(Button)`
  color: white;
  width: 136px;
  hight: 36px;
  padding: 0;
  font-weight: 400;
  border: none;
`;

const StyledEditButton = styled(Button)`
  color: white;
  width: 136px;
  hight: 36px;
  font-weight: 400;
  border-color: #2f5623;
`;

function DetailsZone() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const groupParam = groupByItems[0];
  const [groupedBy, setGroupedBy] = useState(groupParam);
  const [zone, setZone] = useState<ICreateZoneDto | IZone | undefined>(defaultZone);
  const [relatedMissions, setRelatedMissions] = useState<{ missions: IGetRelatedMissions[] } | undefined>(undefined);

  const { data: zoneDetails, refetch: refetchZoneDetails } = useZoneDetails({ id });
  useEffect(() => setZone(zoneDetails), [zoneDetails]);

  const { data: relatedMissionsData, isLoading: isLoadingRelatedMission } = useRelatedMissions({ id });
  useEffect(() => setRelatedMissions(relatedMissionsData), [relatedMissionsData]);

  return (
    <Stack>
      <Stack direction="row" justifyContent="start">
        <StyledButton onClick={() => navigate(urls.zones.landing)} variant="text" startIcon={<ArrowBackIcon />}>
          {t('zone-details')}
        </StyledButton>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '14px' }}>
        <Typography sx={{ fontSize: '22px', fontWeight: 700 }}>{zone?.name}</Typography>
        <StyledEditButton onClick={() => navigate(urls.zones.edit(id))} variant="text" size="medium">
          <EditIcon fontSize="small" style={{ paddingRight: '3px', color: 'white' }} />
          {t('edit')}
        </StyledEditButton>
      </Stack>

      <Typography style={{ color: '#AFAFAF', fontSize: '14px', fontWeight: 400 }}>{zone?.description}</Typography>

      <Stack sx={{ paddingTop: '20px' }}>
        <GroupBy
          items={groupByItems}
          selected={groupedBy}
          onChange={(group: string) => setGroupedBy(group)}
          hideLabel
          count={relatedMissions?.missions?.length || 0}
        />
      </Stack>

      {groupedBy === 'zone-details' && (
        <Stack direction="row" sx={{ justifyContent: 'space-between', paddingTop: '2px' }}>
          <NAIMap nais={zone?.nais as INAI[]} />
          <NAICard nais={zone?.nais as INAI[]} zoneId={(zone as IZone)?.id || ''} refetchDetails={refetchZoneDetails} />
        </Stack>
      )}
      {groupedBy === 'related-missions' && (
        <Stack>
          <RelatedMissions missions={relatedMissions?.missions} isLoading={isLoadingRelatedMission} />
        </Stack>
      )}
    </Stack>
  );
}

export default DetailsZone;
