import React from 'react';
import Header from 'components/header/header';
import { Container, Stack } from '@mui/material';

import { colors } from 'theme';
import {
  adminNavigationItems,
  airDefenceItems,
  pilotNavigationItems,
  topNavigationItems,
  generalItems,
} from './navigation';
import Footer from '../footer/footer';

type IMenu = 'general' | 'pilot' | 'admin' | 'air_defence' | 'istar' | undefined;

const getNavigationItems = (menu: IMenu, empty: boolean | undefined) => {
  switch (menu) {
    case 'pilot':
      if (empty) return [];
      return pilotNavigationItems;
    case 'admin':
      return adminNavigationItems;
    case 'air_defence':
      if (empty) return [];
      return airDefenceItems;
    case 'general':
      if (empty) return [];
      return generalItems;
    case 'istar':
      return topNavigationItems;
    default:
      return [];
  }
};

interface HeaderLayoutProps {
  menu?: IMenu;
  empty?: boolean;
  statusSwitcher?: React.ReactNode;
  bgColor?: string;
  children: React.ReactNode;
}

function HeaderLayout({
  menu = 'istar',
  empty = false,
  statusSwitcher = null,
  children,
  bgColor = colors.grayscale.b2,
}: HeaderLayoutProps) {
  const navigationItems = getNavigationItems(menu, empty);
  return (
    <div style={{ backgroundColor: bgColor, minHeight: '100vh' }}>
      <Header navigationItems={navigationItems} statusSwitcher={statusSwitcher} />
      <Container maxWidth="xl">
        <Stack pb="52px">{children}</Stack>
      </Container>
      <Footer />
    </div>
  );
}

export default HeaderLayout;
