import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { ICrew } from 'types/crew';
import { toast } from 'react-toastify';

export const useCrews = () => {
  const { data, isFetching } = useQuery<ICrew[], Error>({
    queryKey: ['crew-list'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.crews.list(null));
        return response.data.results;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch crews');
        throw err;
      }
    },
  });

  return { data, isFetching };
};

export default useCrews;
