import React from 'react';
import { IconButton, Skeleton, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { urls } from 'router';

import KHTable from 'components/khTable';
import User from '../services/model';

interface Props {
  rows: User[];
  isLoading?: boolean;
}

function UsersTable({ rows, isLoading = false }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <KHTable>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2">#</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('username')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('role')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">{t('status')}</Typography>
          </TableCell>
          <TableCell width="25%">
            <Typography variant="body2">ID</Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading &&
          Array.from(Array(5)).map((i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        {!isLoading &&
          rows.map((row, i) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => navigate(urls.admin.users.edit(row.id))}
              hover
            >
              <TableCell>
                <Typography variant="body1">{i + 1}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{row.username}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t(row.role)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{t(row.status)}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography variant="body1">{row.id}</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="secondary"
                  className="action-icon"
                  onClick={() => navigate(urls.admin.users.edit(row.id))}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </KHTable>
  );
}

export default UsersTable;
