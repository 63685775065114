import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IEventLogEntry } from 'types/eventlog';
import api, { apiURLs } from 'services/api';

export const useThreats = () => {
  const { data, isFetching } = useQuery<IEventLogEntry[]>({
    queryKey: ['threats'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.eventlog.threat);
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch threats');
        throw err;
      }
    },
    refetchInterval: 3000,
  });

  return { data, isFetching };
};

export default useThreats;
