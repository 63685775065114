import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Card, IconButton, Stack, styled, Typography } from '@mui/material';

import { colors } from 'theme';
import { ITarget } from 'types/target';
import TargetCard from '../../targets/components/targetCard';
import Search from '../../../components/search';

interface IProps {
  targets?: ITarget[];
  onClose: (_value: boolean) => void;
  onTargetClick: (_target: ITarget) => void;
}

const DetailsCard = styled(Card)(() => ({
  padding: '30px',
  border: `1px solid ${colors.grayscale.b4}`,
  backgroundColor: colors.grayscale.b3,
}));

function TargetSelect({ targets = [], onClose, onTargetClick }: IProps) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');

  return (
    <Stack gap={1} flex={1}>
      <DetailsCard>
        <Stack flexDirection="row" justifyItems="center" mb={2} gap="14px">
          <IconButton
            aria-label="close"
            onClick={() => onClose(false)}
            style={{ padding: '0', color: colors.grayscale.g2 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h2">{t('select_target')}</Typography>
        </Stack>
        <Stack gap={1}>
          <Search onSearch={(text) => setSearchValue(text)} />
          {targets
            ?.filter((target: ITarget) => target.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
            .map((target: ITarget) => (
              <TargetCard
                key={target.id}
                target={target}
                showStatus={false}
                selectTarget={onTargetClick}
                cursor="pointer"
              />
            ))}
        </Stack>
      </DetailsCard>
    </Stack>
  );
}

export default TargetSelect;
