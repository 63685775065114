import React from 'react';
import { Stack, Typography } from '@mui/material';

import { colors } from 'theme';

interface IProps {
  bgColor?: string;
}

function Footer({ bgColor = colors.grayscale.b2 }: IProps) {
  return (
    <Stack
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        borderTop: `1px solid ${colors.grayscale.b4}`,
        backgroundColor: bgColor,
        width: '100%',
        zIndex: 1000,
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '46px' }}>
        <Typography variant="body2">Xартія, {new Date().getFullYear()} ©</Typography>
      </Stack>
    </Stack>
  );
}

export default Footer;
