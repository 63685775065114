import { useQuery } from '@tanstack/react-query';
import api, { apiURLs } from 'services/api';
import { IZone } from 'types/zone';
import { toast } from 'react-toastify';

export const useZones = () => {
  const { data, isFetching } = useQuery<IZone[], Error>({
    queryKey: ['zone-list'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.zones.list(null));
        return response.data.results;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch zones');
        throw err;
      }
    },
  });

  return { data, isFetching };
};

export default useZones;
