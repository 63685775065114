import React from 'react';

function CommentIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M2.25675 3.6C2.25675 2.8575 2.8575 2.25 3.6 2.25H14.4C15.1425 2.25 15.75 2.8575 15.75 3.6V11.7C15.75 12.4425 15.1425 13.05 14.4 13.05H4.95L2.25 15.75L2.25675 3.6Z"
          fill="#5F5F5F"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CommentIcon;
