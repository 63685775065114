import React from 'react';

function ErrorIcon() {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="17.5" r="17.5" fill="#E25850" />
      <path
        d="M17.5 18.5C17.7833 18.5 18.021 18.404 18.213 18.212C18.4043 18.0207 18.5 17.7833 18.5 17.5V13.475C18.5 13.1917 18.4043 12.9583 18.213 12.775C18.021 12.5917 17.7833 12.5 17.5 12.5C17.2167 12.5 16.9793 12.5957 16.788 12.787C16.596 12.979 16.5 13.2167 16.5 13.5V17.525C16.5 17.8083 16.596 18.0417 16.788 18.225C16.9793 18.4083 17.2167 18.5 17.5 18.5ZM17.5 22.5C17.7833 22.5 18.021 22.404 18.213 22.212C18.4043 22.0207 18.5 21.7833 18.5 21.5C18.5 21.2167 18.4043 20.979 18.213 20.787C18.021 20.5957 17.7833 20.5 17.5 20.5C17.2167 20.5 16.9793 20.5957 16.788 20.787C16.596 20.979 16.5 21.2167 16.5 21.5C16.5 21.7833 16.596 22.0207 16.788 22.212C16.9793 22.404 17.2167 22.5 17.5 22.5ZM17.5 27.5C16.1167 27.5 14.8167 27.2373 13.6 26.712C12.3833 26.1873 11.325 25.475 10.425 24.575C9.525 23.675 8.81267 22.6167 8.288 21.4C7.76267 20.1833 7.5 18.8833 7.5 17.5C7.5 16.1167 7.76267 14.8167 8.288 13.6C8.81267 12.3833 9.525 11.325 10.425 10.425C11.325 9.525 12.3833 8.81233 13.6 8.287C14.8167 7.76233 16.1167 7.5 17.5 7.5C18.8833 7.5 20.1833 7.76233 21.4 8.287C22.6167 8.81233 23.675 9.525 24.575 10.425C25.475 11.325 26.1873 12.3833 26.712 13.6C27.2373 14.8167 27.5 16.1167 27.5 17.5C27.5 18.8833 27.2373 20.1833 26.712 21.4C26.1873 22.6167 25.475 23.675 24.575 24.575C23.675 25.475 22.6167 26.1873 21.4 26.712C20.1833 27.2373 18.8833 27.5 17.5 27.5Z"
        fill="white"
      />
    </svg>
  );
}

export default ErrorIcon;
