import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IGetZoneListResponse } from 'types/zone';
import api, { apiURLs } from 'services/api';

export const useZones = () => {
  const { data, isFetching } = useQuery<IGetZoneListResponse>({
    queryKey: ['zone-list'],
    queryFn: async () => {
      try {
        const response = await api.get(apiURLs.zones.listAAD(null, 1, 100));
        return response.data;
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch zones');
        throw err;
      }
    },
  });

  return { data, isFetching };
};

export default useZones;
