import { Grid, Typography } from '@mui/material';
import React from 'react';
import PasswordField from 'components/passwordField';
import { getTextFieldErrorProps } from 'services/helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  passwords: { password: string; confirm_password: string };
  setPasswords: (_key: string, _value: string) => void;
  errors: any;
}

export default function PasswordForm({ passwords, setPasswords, errors }: IProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} alignContent="center">
        <Typography variant="h3">{t('password')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <PasswordField
          onChange={(p) => setPasswords('password', p)}
          value={passwords?.password}
          autoComplete="new-password"
          {...getTextFieldErrorProps(errors, 'password', t)}
        />
      </Grid>
      <Grid item xs={12} md={2} />
      <Grid item xs={12} md={3} alignContent="center">
        <Typography variant="h3">{t('confirmPassword')}</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <PasswordField
          onChange={(cp) => setPasswords('confirm_password', cp)}
          value={passwords?.confirm_password}
          autoComplete="new-password"
          {...getTextFieldErrorProps(errors, 'confirm_password', t)}
        />
      </Grid>
      <Grid item xs={12} md={2} />
      <Grid item xs={12}>
        <Typography color="secondary">- {t('password_must_at_least_eight_characters_long')}</Typography>
        <Typography color="secondary">- {t('password_must_contain_one_uppercase_letter')}</Typography>
        <Typography color="secondary">- {t('password_must_contain_one_lowercase_letter')}</Typography>
      </Grid>
    </Grid>
  );
}
